import React, { useState, useEffect } from "react";
import { Box, Divider } from "@mui/material";

import { get, ref } from "firebase/database";

import { BookingHeader } from "./BookingHeader";
import { BookingDetails } from "./BookingDetails";

import { Item } from "../settings/Item";

import { database } from "../../../firebase/firebase";

interface Props {
  booking: any;
  done: boolean;
}

export default function BookingCard({ booking, done }: Props) {
  const [driver, setDriver] = useState<any>();
  const [vehicle, setVehicle] = useState<any>();

  const itemStyle = done
    ? {
        backgroundColor: "#f0fdf4", // Soft green background
        border: "1px solid #34d399", // Subtle green border
        borderRadius: "8px", // Softer corners for a modern look
      }
    : {};

  useEffect(() => {
    if (booking.driver) {
      get(ref(database, `drivers/${booking.driver}`)).then((snapshot: any) => {
        if (snapshot.exists()) {
          setDriver(snapshot.val());
        }
      });
    }
  }, [booking.driver]);

  useEffect(() => {
    if (booking.vehicle) {
      get(ref(database, `vehicles/${booking.vehicle}`)).then(
        (snapshot: any) => {
          if (snapshot.exists()) {
            setVehicle(snapshot.val());
          }
        }
      );
    }
  }, [booking.vehicle]);

  return (
    <Box>
      <Item elevation={3} sx={itemStyle}>
        <BookingHeader booking={booking} driver={driver} vehicle={vehicle} />
        <Divider sx={{ mt: 1, mb: 1 }} />
        <BookingDetails booking={booking} driver={driver} vehicle={vehicle} />
      </Item>
    </Box>
  );
}
