import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Avatar,
  Button,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";

import { getDatabase, onValue, ref, remove } from "firebase/database";
import { deleteObject, getStorage, ref as storageRef } from "firebase/storage";

import { Title } from "./Title";
import { Item } from "./Item";

import { useNotification } from "../../../contexts/NotificationContext";

export default function Drivers() {
  const [drivers, setDrivers] = useState<any>();

  const { showNotification } = useNotification();
  const navigate = useNavigate();

  useEffect(() => {
    const db = getDatabase();
    const driversRef = ref(db, "drivers");

    const unsubscribe = onValue(
      driversRef,
      (snapshot) => {
        const data = snapshot.val();

        if (data) {
          const formattedData = Object.entries(data).map(([key, value]) => ({
            key,
            value,
          }));

          setDrivers(formattedData);
        } else {
          setDrivers(null);
        }
      },
      (error) => {
        showNotification("error", "Erro ao carregar motoristas.");
      }
    );

    return () => unsubscribe();
  }, []);

  const handleDeleteDriver = async (driver: string, key: string) => {
    try {
      const db = getDatabase();
      const driversRef = ref(db, `drivers/${key}`);

      const storage = getStorage();
      const photoRef = storageRef(storage, `drivers/${key}`);

      await remove(driversRef);
      await deleteObject(photoRef);

      showNotification("success", `Motorista ${driver} removido com sucesso.`);
    } catch (error) {
      showNotification(
        "error",
        `Ocorreu um erro ao remover o motorista ${driver} da base de dados.`
      );
    }
  };

  return (
    <>
      <Title color="primary" variant="h6">
        Motoristas
      </Title>
      <Item elevation={3}>
        <Stack spacing={1}>
          {!drivers && (
            <Typography variant="body1">
              Nenhum motorista adicionado ainda.
            </Typography>
          )}
          {drivers && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <strong>Foto</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>Nome</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>Telefone</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Editar</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong>Excluir</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {drivers.map(({ key, value }: any) => {
                    return (
                      <TableRow key={key}>
                        <TableCell align="left">
                          <Avatar src={value.photo} />
                        </TableCell>
                        <TableCell align="left">{value.name}</TableCell>
                        <TableCell align="left">{value.phone}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            color="info"
                            onClick={() => navigate(`edit/${key}`)}
                          >
                            <Edit />
                          </IconButton>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            color="error"
                            onClick={() => {
                              handleDeleteDriver(value.name, key);
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            startIcon={<Add />}
            onClick={() => navigate("add")}
          >
            Adicionar motorista
          </Button>
        </Stack>
      </Item>
    </>
  );
}
