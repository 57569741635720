import React from "react";
import { Alert, AlertColor, AlertTitle, Snackbar } from "@mui/material";

interface Props {
  children: string;
  open: boolean;
  onClose: () => void;
  type: AlertColor;
  title?: string;
}

export default function Notification({
  children,
  open,
  onClose,
  type,
  title,
}: Props) {
  return (
    <div>
      <Snackbar
        open={open}
        onClose={onClose}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={onClose}
          severity={type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {title && <AlertTitle>{title}</AlertTitle>}
          {children}
        </Alert>
      </Snackbar>
    </div>
  );
}
