import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  Box,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Stack,
  Button,
  Divider,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Phone, WhatsApp } from "@mui/icons-material";

import { get, ref } from "firebase/database";

import { database } from "../../firebase/firebase";

import LoadingScreen from "../../utils/LoadingScreen";

import { Title } from "../components/settings/Title";
import BookingCard from "../components/booking/BookingCard";
import { Item } from "../components/settings/Item";

export default function ClientBookingsPage() {
  const [loading, setLoading] = useState(true);
  const [bookings, setBookings] = useState<any[]>([]);

  const { phone } = useParams<{ phone: string }>();
  const navigate = useNavigate();

  const goBack = () => navigate("/admin-management/others/clients");

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const bookingsRef = ref(database, "/booking");
        const snapshot = await get(bookingsRef);

        if (snapshot.exists()) {
          setLoading(false);

          const rawData = snapshot.val();

          const clientBookings = Object.entries(rawData)
            .map(([id, booking]: any) => ({
              ...booking,
              id,
            }))
            .filter(
              (item: any) =>
                item.phone &&
                item.phone.toString().replace(/[^\d]/g, "") === phone
            )
            .sort((a: any, b: any) => a.date - b.date);

          setBookings(clientBookings);
        }
      } catch (error) {
        console.error("Error fetching client bookings:", error);
      }
    };

    fetchBookings();
  }, [phone]);

  const generateWhatsAppLink = (phoneNumber: string) => {
    const cleanNumber = phoneNumber.toString().replace(/\D/g, "");

    return window.open(`https://wa.me/${cleanNumber}`);
  };

  const exchangeRates: { [key: string]: number } = {
    dollar: 0.16,
    euro: 0.16,
    pesos: 166.21,
    real: 1,
  };

  const totalValue = bookings.reduce((sum, booking) => {
    const price = parseFloat(booking.value || "0");
    const currency = booking.currency ? booking.currency.toLowerCase() : "real";
    const rate = exchangeRates[currency];

    return sum + price / (rate || 1);
  }, 0);

  if (loading || !bookings[0]) {
    return <LoadingScreen />;
  }

  return (
    <Box>
      <AppBar position="static" sx={{ backgroundColor: "#181818" }}>
        <Toolbar>
          <IconButton
            onClick={goBack}
            size="large"
            edge="start"
            aria-label="menu"
            color="inherit"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {bookings[0].name || "Cliente"}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 2 }}>
        <Title color="primary" variant="h6">
          Informações
        </Title>
        <Item
          elevation={3}
          sx={{
            backgroundColor: "#e3f2fd",
            border: "1px solid #1976d2",
            borderRadius: "8px",
            alignContent: "center",
          }}
        >
          <Typography variant="body1">Nome</Typography>
          <Typography variant="h6">{bookings[0].name}</Typography>
          <br />
          <Typography variant="body1">Telefone</Typography>
          <Typography variant="h6">{bookings[0].phone}</Typography>
        </Item>
        <Stack
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection="row"
          sx={{ gap: 2 }}
        >
          <Item
            elevation={3}
            sx={{
              backgroundColor: "#e3f2fd",
              border: "1px solid #1976d2",
              borderRadius: "8px",
              alignContent: "center",
              width: "50%",
              textAlign: "center",
            }}
          >
            <Box>
              <Typography variant="h5">{bookings.length}</Typography>
              <Typography variant="body1">Viagens</Typography>
            </Box>
          </Item>
          <Item
            elevation={3}
            sx={{
              backgroundColor: "#e3f2fd",
              border: "1px solid #1976d2",
              borderRadius: "8px",
              alignContent: "center",
              width: "50%",
              textAlign: "center",
            }}
          >
            <Box>
              <Typography variant="h5">R$ {totalValue.toFixed(0)}</Typography>
              <Typography variant="body1">Total gasto</Typography>
            </Box>
          </Item>
        </Stack>
        {bookings.length > 1 && (
          <Item
            elevation={3}
            sx={{
              backgroundColor: "#e3f2fd",
              border: "1px solid #1976d2",
              borderRadius: "8px",
              alignContent: "center",
              textAlign: "center",
            }}
          >
            <Box>
              <Typography variant="h5">
                ~ R$ {(totalValue / bookings.length).toFixed(0)}
              </Typography>
              <Typography variant="body1">Média por viagem</Typography>
            </Box>
          </Item>
        )}
        <Divider textAlign="center">
          <Typography color="GrayText">Contato</Typography>
        </Divider>
        <Stack sx={{ my: 2 }}>
          <Stack
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
              flexDirection: "row",
            }}
          >
            <Button
              href={`tel://${bookings[0].phone}`}
              fullWidth
              variant="outlined"
              startIcon={<Phone />}
              sx={{
                width: "50%",
                borderColor: "black",
                color: "black",
              }}
            >
              Telefone
            </Button>
            <Button
              onClick={() => generateWhatsAppLink(bookings[0].phone)}
              fullWidth
              variant="contained"
              startIcon={<WhatsApp />}
              sx={{
                backgroundColor: "#25d366 !important",
                color: "white",
                width: "50%",
              }}
            >
              WhatsApp
            </Button>
          </Stack>
        </Stack>
        <Title color="primary" variant="h6">
          Viagens
        </Title>
        {bookings.length === 0 ? (
          <Typography>Não foi encontrada nenhuma viagem.</Typography>
        ) : (
          bookings.map((booking: any, index) => (
            <BookingCard booking={booking} key={index} done={false} />
          ))
        )}
      </Box>
    </Box>
  );
}
