import React, { useEffect, useState } from "react";
import {
  Button,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";

import { getDatabase, onValue, push, ref, remove } from "firebase/database";

import { Item } from "./Item";
import { Title } from "./Title";

import { useNotification } from "../../../contexts/NotificationContext";

export default function Suggestions() {
  const [suggestions, setSuggestions] = useState<any>(null);
  const [newWord, setNewWord] = useState<any>();
  const [isEditing, setIsEditing] = useState(false);

  const { showNotification } = useNotification();

  useEffect(() => {
    const db = getDatabase();
    const suggestionsRef = ref(db, "suggestions");

    const unsubscribe = onValue(
      suggestionsRef,
      (snapshot) => {
        const data = snapshot.val();

        if (data) {
          const formattedData = Object.entries(data).map(([key, value]) => ({
            key,
            value,
          }));

          setSuggestions(formattedData);
        } else {
          setSuggestions(null);
        }
      },
      (error) => {
        showNotification("error", "Erro ao carregar sugestões.");
      }
    );

    return () => unsubscribe();
  }, []);

  const handleToggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleSuggestionChange = (ev: any) => {
    setNewWord(ev.target.value);
  };

  const handleAddSuggestion = async () => {
    if (!newWord) {
      return showNotification(
        "warning",
        "Não é possível adicionar uma sugestão em branco."
      );
    }

    try {
      const db = getDatabase();
      const suggestionsRef = ref(db, "suggestions");

      await push(suggestionsRef, newWord);

      handleToggleEdit();

      showNotification("success", `Sugestão '${newWord}' criada com sucesso.`);
    } catch (err) {
      showNotification("error", "Erro ao adicionar sugestão.");
    }
  };

  const handleDeleteSuggestion = async (suggestion: string, key: string) => {
    try {
      const db = getDatabase();
      const suggestionRef = ref(db, `suggestions/${key}`);

      await remove(suggestionRef);

      showNotification(
        "success",
        `Sugestão '${suggestion}' removida com sucesso.`
      );
    } catch (error) {
      showNotification(
        "error",
        `Ocorreu um erro ao remover a sugestão '${suggestion}' da base de dados.`
      );
    }
  };

  return (
    <>
      <Title variant="h6" color="primary">
        Sugestões
      </Title>
      <Item elevation={3}>
        <Stack spacing={1}>
          {!isEditing && (
            <>
              {!suggestions && (
                <Typography variant="body1">
                  Nenhuma sugestão adicionada ainda.
                </Typography>
              )}
              {suggestions && (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          <strong>Sugestão</strong>
                        </TableCell>
                        <TableCell align="right">
                          <strong>Excluir</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {suggestions.map(({ key, value }: any) => {
                        return (
                          <TableRow key={key}>
                            <TableCell align="left">{value}</TableCell>
                            <TableCell align="right">
                              <IconButton
                                onClick={() => {
                                  handleDeleteSuggestion(value, key);
                                }}
                              >
                                <Delete color="error" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </>
          )}
          {isEditing && (
            <>
              <TextField
                required
                placeholder="Ex. Aeroporto Hercílio Luz"
                label="Sugestão"
                onChange={handleSuggestionChange}
              />
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={handleAddSuggestion}
              >
                Adicionar
              </Button>
              <Button
                fullWidth
                color="error"
                variant="outlined"
                onClick={handleToggleEdit}
              >
                Cancelar
              </Button>
            </>
          )}
          {!isEditing && (
            <>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                startIcon={<Add />}
                onClick={handleToggleEdit}
              >
                Adicionar sugestão
              </Button>
            </>
          )}
        </Stack>
      </Item>
    </>
  );
}
