import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router";
import { signInWithEmailAndPassword } from "firebase/auth";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Email, Key, Visibility, VisibilityOff } from "@mui/icons-material";

import { auth } from "../firebase/firebase";

import { useNotification } from "../contexts/NotificationContext";
import { useAuth } from "../contexts/AuthContext";

import black_logo from "../assets/images/black_logo.png";
import Modal from "../components/Modal";

export default function Authenticate() {
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const { showNotification } = useNotification();
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setOpen(true);
    }
  }, [user]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleSignIn = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      showNotification(
        "success",
        `Você entrou como ${userCredential.user.email}`
      );

      return navigate("/admin-management");
    } catch (err) {
      showNotification("error", "Usuário inválido.");
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#262626",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            p: 3,
            textAlign: "center",
            borderRadius: 2,
          }}
        >
          <Stack spacing={2}>
            <span style={{ display: "flex", justifyContent: "center" }}>
              <Link to="/">
                <img style={{ width: "224px" }} src={black_logo} alt="logo" />
              </Link>
            </span>
            <Typography variant="h5">Autenticação</Typography>
            <FormControl>
              <InputLabel>E-mail *</InputLabel>
              <OutlinedInput
                type="email"
                startAdornment={
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                }
                fullWidth
                placeholder="exemplo@gmail.com"
                label="E-mail *"
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <InputLabel>Senha *</InputLabel>
              <OutlinedInput
                startAdornment={
                  <InputAdornment position="start">
                    <Key />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={
                        showPassword
                          ? "hide the password"
                          : "display the password"
                      }
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      onMouseUp={handleMouseUpPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                type={showPassword ? "text" : "password"}
                fullWidth
                placeholder="senha1234"
                label="Senha *"
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <Button onClick={handleSignIn} fullWidth variant="contained">
              Entrar
            </Button>
          </Stack>
        </Paper>
      </Box>
      {user && (
        <Modal
          open={open}
          onClose={handleClose}
          onClick={() => navigate("/admin-management")}
          title={user.email}
          description="Você já possui uma sessão ativa, deseja continuar?"
          closeButton="Não"
          continueButton="Continuar"
        />
      )}
    </>
  );
}
