import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Avatar,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { CloudUpload, Delete, DriveEta, Phone } from "@mui/icons-material";
import { styled } from "@mui/styles";

import { getDatabase, push, ref, update } from "firebase/database";
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytesResumable,
} from "firebase/storage";
import { getStorage } from "firebase/storage";

import { useNotification } from "../../contexts/NotificationContext";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

interface Props {
  id?: string;
  driver?: any;
}

export default function DriverForm({ id, driver }: Props) {
  const [data, setData] = useState<any>({
    name: "",
    phone: "",
    photo: "",
  });
  const [file, setFile] = useState<any>();
  const [progress, setProgress] = useState(0);
  const [key, setKey] = useState<any>();

  const { showNotification } = useNotification();
  const navigate = useNavigate();

  useEffect(() => {
    if (id && driver) {
      setKey(id);

      setData({
        name: driver.name || "",
        phone: driver.phone || "",
        photo: driver.photo || "",
      });
    }
  }, [id, driver]);

  const handleChange = (key: string, value: string | number) => {
    setData((prev: any) => ({ ...prev, [key]: value }));
  };

  const handleFileChange = (e: any) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleFileRemove = () => {
    setFile(null);
    setData((prev: any) => ({ ...prev, photo: null }));
  };

  const handleSubmit = async (e: any) => {
    if (!data.name || !data.phone || !file) {
      return showNotification(
        "warning",
        "Por favor, preencha todos os campos obrigatórios."
      );
    }

    try {
      const db = getDatabase();
      const driversRef = ref(db, "drivers");

      const newDriverRef = await push(driversRef, data);

      const storage = getStorage();
      const fileRef = storageRef(storage, `drivers/${newDriverRef.key}`);

      const uploadTask = uploadBytesResumable(fileRef, file);

      setProgress(1);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          setProgress(progress);
        },
        (error) => {
          setProgress(0);
          showNotification(
            "error",
            "Ocorreu um erro ao salvar a imagem na base de dados."
          );
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

          await update(newDriverRef, { photo: downloadURL });

          showNotification("success", "Motorista cadastrado com sucesso.");
          navigate("/admin-management");
        }
      );
    } catch (err) {
      showNotification("error", "Ocorreu um erro ao adicionar motorista.");
    }
  };

  const handleEdit = async () => {
    try {
      const db = getDatabase();
      const driverRef = ref(db, `drivers/${key}`);

      setProgress(1);

      if (file) {
        console.log(file);
        const storage = getStorage();
        const fileRef = storageRef(storage, `drivers/${key}`);

        const uploadTask = uploadBytesResumable(fileRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

            setProgress(progress);
          },
          (error) => {
            setProgress(0);
            showNotification(
              "error",
              "Ocorreu um erro ao salvar a imagem na base de dados."
            );
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

            await update(driverRef, { ...data, photo: downloadURL });
          }
        );
      } else if (!file && data.photo) {
        await update(driverRef, data);
      } else {
        setProgress(0);
        return showNotification(
          "warning",
          "Por favor, preencha todos os campos obrigatórios."
        );
      }

      navigate("/admin-management");

      showNotification("success", "Motorista atualizado com sucesso.");
    } catch (err) {
      showNotification("error", "Ocorreu um erro ao atualizar motorista.");
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      noValidate
      autoCapitalize="words"
      autoComplete="no"
    >
      <FormControl sx={{ width: "100%" }}>
        <Stack spacing={2}>
          <Typography variant="body1" component="h3">
            Foto *
          </Typography>
          <FormControl>
            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                gap: 2,
              }}
            >
              <Avatar src={(file && URL.createObjectURL(file)) || data.photo} />
              <Button
                component="label"
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUpload />}
              >
                Inserir foto
                <VisuallyHiddenInput type="file" onChange={handleFileChange} />
              </Button>
              <Button
                disabled={!file && !data.photo}
                component="label"
                variant="outlined"
                color="error"
                startIcon={<Delete />}
                onClick={handleFileRemove}
              >
                Remover
              </Button>
            </Stack>
          </FormControl>
          <Typography variant="body1" component="h3">
            Informações
          </Typography>
          <FormControl>
            <InputLabel style={{ color: "#181818" }}>Nome *</InputLabel>
            <OutlinedInput
              error={data.name === ""}
              startAdornment={
                <InputAdornment position="start">
                  <DriveEta />
                </InputAdornment>
              }
              label="Nome *"
              onChange={(e) => handleChange("name", e.target.value)}
              placeholder="Nome do motorista"
              value={data.name}
            />
            {!data.name && (
              <FormHelperText sx={{ color: "red" }}>
                Este campo é obrigatório.
              </FormHelperText>
            )}
          </FormControl>
          <FormControl>
            <InputLabel style={{ color: "#181818" }}>
              Número de telefone *
            </InputLabel>
            <OutlinedInput
              error={!data.phone}
              startAdornment={
                <InputAdornment position="start">
                  <Phone />
                </InputAdornment>
              }
              label="Número de telefone *"
              onChange={(e) => handleChange("phone", e.target.value)}
              placeholder="Telefone"
              value={data.phone}
            />
            {!data.phone && (
              <FormHelperText sx={{ color: "red" }}>
                Este campo é obrigatório.
              </FormHelperText>
            )}
          </FormControl>
          <Divider />
          <Button
            disabled={progress !== 0}
            onClick={key ? handleEdit : handleSubmit}
            variant="contained"
            color="primary"
          >
            {key ? "Atualizar" : "Adicionar"}
          </Button>
        </Stack>
      </FormControl>
    </form>
  );
}
