import React from "react";
import {
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
} from "@mui/material";
import { Menu, Restore, TimeToLeave } from "@mui/icons-material";

interface Props {
  value: number;
  dataLength: number;
  onChange: (value: number) => void;
}

export default function PanelNavigation({
  value,
  dataLength,
  onChange,
}: Props) {
  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation
        sx={{ backgroundColor: "#181818", pb: 3 }}
        showLabels
        value={value}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
      >
        <BottomNavigationAction
          sx={{
            color: "gray",
            "&.Mui-selected": {
              color: "white",
            },
          }}
          label="Menu"
          icon={<Menu />}
        />
        <BottomNavigationAction
          sx={{
            color: "gray",
            "&.Mui-selected": {
              color: "white",
            },
          }}
          label="Anteriores"
          icon={
            value === 1 ? (
              <Badge
                sx={{ "& .MuiBadge-badge": { right: -3, top: 6 } }}
                badgeContent={dataLength}
                color="primary"
              >
                <Restore />
              </Badge>
            ) : (
              <Restore />
            )
          }
        />
        <BottomNavigationAction
          sx={{
            color: "gray",
            "&.Mui-selected": {
              color: "white",
            },
          }}
          label="Próximas"
          icon={
            value === 2 ? (
              <Badge
                sx={{ "& .MuiBadge-badge": { right: -3, top: 6 } }}
                badgeContent={dataLength}
                color="primary"
              >
                <TimeToLeave />
              </Badge>
            ) : (
              <TimeToLeave />
            )
          }
        />
      </BottomNavigation>
    </Paper>
  );
}
