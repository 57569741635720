import React, { useState } from "react";
import { Box, IconButton, Typography, Stack } from "@mui/material";
import { MoreHoriz, Person } from "@mui/icons-material";

import { ref, remove } from "firebase/database";

import BottomSheetModal from "./BottomSheetModal";
import { BookingModal } from "./BookingModal";

import { database } from "../../../firebase/firebase";
import { useNotification } from "../../../contexts/NotificationContext";

interface Props {
  booking: any;
  driver: any;
  vehicle: any;
}

export function BookingHeader({ booking, vehicle, driver }: Props) {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [bottomSheetOpen, setBottomSheetOpen] = useState(false);

  const { showNotification } = useNotification();

  const handleDelete = () => {
    remove(ref(database, `booking/${booking.id}`))
      .then(() => {
        showNotification("success", "Reserva excluída com sucesso!");
        handleCloseModal();
      })
      .catch((error) => {
        console.error("Error writing data: ", error);
        showNotification("error", "Um erro aconteceu ao excluir essa reserva.");
      });
  };

  const handleOpenModal = () => setDeleteModalOpen(true);
  const handleCloseModal = () => setDeleteModalOpen(false);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Person sx={{ mr: 1 }} />
        <Typography sx={{ fontWeight: "bold" }} variant="body1">
          {booking.name}
        </Typography>
      </Stack>
      <IconButton onClick={() => setBottomSheetOpen(true)}>
        <MoreHoriz />
      </IconButton>
      <BottomSheetModal
        open={bottomSheetOpen}
        booking={booking}
        driver={driver}
        vehicle={vehicle}
        handleClose={() => setBottomSheetOpen(false)}
        handleOpenModal={handleOpenModal}
      />
      <BookingModal
        open={deleteModalOpen}
        handleClose={handleCloseModal}
        handleDelete={handleDelete}
      />
    </Box>
  );
}
