import React from "react";
import { useNavigate } from "react-router";

import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import {
  SimCardDownload,
  Edit,
  Delete,
  IosShare,
  WhatsApp,
  Reply,
  Person,
  TaskAlt,
} from "@mui/icons-material";

import { handleDownloadPDF } from "./downloadPdf";

import { useNotification } from "../../../contexts/NotificationContext";
import { BookingActions } from "./BookingActions";

interface Props {
  open: boolean;
  booking: any;
  driver: any;
  vehicle: any;
  handleOpenModal: any;
  handleClose: () => void;
}

export default function BottomSheetModal({
  open,
  booking,
  driver,
  vehicle,
  handleOpenModal,
  handleClose,
}: Props) {
  const navigate = useNavigate();
  const { showNotification } = useNotification();

  const generateVoucherWhatsAppLink = (phoneNumber: string) => {
    const cleanNumber = phoneNumber.toString().replace(/\D/g, "");

    return window.open(
      `https://wa.me/${cleanNumber}?text=https://www.transferonfloripa.com.br/voucher/${booking.id}`
    );
  };

  const downloadPdf = () => {
    showNotification("info", "Baixando PDF...");
    handleDownloadPDF(booking, vehicle, driver);
  };

  const handleShare = ({ url, text }: any) => {
    if (navigator.share) {
      navigator
        .share({
          title: text,
          url: url,
        })
        .then(() => console.log("Shared successfully"))
        .catch((error) => console.error("Error sharing:", error));
    } else {
      showNotification("warning", "Não foi possível compartilhar o voucher.");
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          display: "flex",
          m: 0,
          p: 0,
        }}
        closeAfterTransition
      >
        <Slide
          direction={"up"}
          in={open}
          mountOnEnter
          unmountOnExit
          appear={true}
          timeout={{ enter: 300, exit: 300 }}
        >
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              width: "100%",
              maxWidth: "100%",
              boxSizing: "border-box",
              backgroundColor: "background.paper",
              boxShadow: 3,
              borderRadius: "16px 16px 0 0",
              p: 2,
              pb: 3,
            }}
          >
            <Box
              sx={{
                mb: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Typography variant="h6">Opções</Typography>
              <Box sx={{ display: "flex", gap: 1 }}>
                {booking.phone && (
                  <IconButton
                    onClick={() => generateVoucherWhatsAppLink(booking.phone)}
                    sx={{ backgroundColor: "#e4e4e4" }}
                  >
                    <WhatsApp color="primary" />
                  </IconButton>
                )}
                <IconButton
                  onClick={() => {
                    handleShare({
                      text: "Acompanhe seu voucher!",
                      url: `https://www.transferonfloripa.com.br/voucher/${booking.id}`,
                    });
                  }}
                  sx={{ backgroundColor: "#e4e4e4" }}
                >
                  <IosShare color="primary" />
                </IconButton>
              </Box>
            </Box>
            <Stack direction="column" spacing={1}>
              <Button
                fullWidth
                target="_blank"
                variant="contained"
                href={`https://www.transferonfloripa.com.br/voucher/${booking.id}`}
                startIcon={<Reply />}
              >
                Abrir voucher
              </Button>
              <Button
                fullWidth
                target="_self"
                variant="contained"
                href={`http://www.transferonfloripa.com.br/checkin/${booking.id}`}
                startIcon={<TaskAlt />}
              >
                Instruções de embarque
              </Button>
              {booking.phone && (
                <Button
                  fullWidth
                  target="_self"
                  variant="contained"
                  href={`http://www.transferonfloripa.com.br/admin-management/others/clients/${booking.phone
                    .toString()
                    .replace(/[^\d]/g, "")}`}
                  startIcon={<Person />}
                >
                  Ver cliente
                </Button>
              )}
              <Divider sx={{ m: 2 }}>
                <Typography color="GrayText">Acompanhamento</Typography>
              </Divider>
              <BookingActions booking={booking} expanded={true} />
              <Divider sx={{ m: 2 }}>
                <Typography color="GrayText">Outros</Typography>
              </Divider>
              <Button
                fullWidth
                onClick={downloadPdf}
                startIcon={<SimCardDownload />}
                color="primary"
                variant="contained"
              >
                Baixar PDF
              </Button>
              <Button
                fullWidth
                onClick={() => navigate(`/admin-management/edit/${booking.id}`)}
                startIcon={<Edit />}
                variant="contained"
              >
                Editar
              </Button>
              <Button
                fullWidth
                onClick={handleOpenModal}
                startIcon={<Delete />}
                color="error"
                variant="contained"
              >
                Excluir
              </Button>
            </Stack>
          </Box>
        </Slide>
      </Modal>
    </>
  );
}
