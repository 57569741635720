import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Box,
  IconButton,
  Toolbar,
  Typography,
  AppBar,
  Paper,
  useTheme,
  Chip,
  TextField,
  Tooltip,
  Divider,
} from "@mui/material";
import { styled } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ArrowForward, Person, Search } from "@mui/icons-material";

import { get, ref } from "firebase/database";

import { database } from "../../firebase/firebase";

import LoadingScreen from "../../utils/LoadingScreen";
import { Title } from "../components/settings/Title";

const Item = styled(Paper)(() => ({
  padding: 12,
}));

export default function ClientsPage() {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState<any>([]);
  const [groupedClients, setGroupedClients] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState<any>("");

  const navigate = useNavigate();
  const theme = useTheme();

  const goBack = () => navigate("/admin-management");

  const groupClients = useCallback((data: any) => {
    const grouped: any = {};

    Object.values(data).forEach((item: any) => {
      const normalizedPhone = normalizePhone(item.phone || "unknown");

      if (!normalizedPhone || normalizedPhone === "0") {
        return;
      }

      if (!grouped[normalizedPhone]) {
        grouped[normalizedPhone] = {
          phone: normalizedPhone,
          name: item.name,
          count: 0,
          entries: [],
        };
      }

      grouped[normalizedPhone].count += 1;
      grouped[normalizedPhone].entries.push(item);
    });

    return Object.values(grouped);
  }, []);

  const groupByInitialLetter = useCallback((clientsList: any[]) => {
    const groupedByLetter: any = {};

    clientsList.forEach((client) => {
      const initial = client.name?.[0]?.toUpperCase() || "#";

      if (!groupedByLetter[initial]) {
        groupedByLetter[initial] = [];
      }

      groupedByLetter[initial].push(client);
    });

    return groupedByLetter;
  }, []);

  const filterClients = useCallback(() => {
    if (!searchTerm) {
      return groupByInitialLetter(clients);
    }

    const filteredClients = clients.filter(
      (client: any) =>
        client.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        client.phone.includes(searchTerm)
    );

    return groupByInitialLetter(filteredClients);
  }, [searchTerm, clients, groupByInitialLetter]);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const clientsRef = ref(database, "/booking");
        const snapshot = await get(clientsRef);

        if (snapshot.exists()) {
          setLoading(false);

          const rawData = snapshot.val();
          const groupedClients = groupClients(rawData);

          setClients(groupedClients);

          const clientsByLetter = groupByInitialLetter(groupedClients);

          setGroupedClients(clientsByLetter);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchClients();
  }, [groupByInitialLetter, groupClients]);

  useEffect(() => {
    setGroupedClients(filterClients());
  }, [searchTerm, filterClients]);

  const normalizePhone = (phone: string) => {
    return phone.toString().replace(/[^\d]/g, "");
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Box>
      <AppBar position="static" sx={{ backgroundColor: "#181818" }}>
        <Toolbar>
          <IconButton
            onClick={goBack}
            size="large"
            edge="start"
            aria-label="menu"
            color="inherit"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Clientes
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
        <Item
          elevation={1}
          sx={{
            p: "8px !important",
            backgroundColor: "#e3f2fd",
            border: "1px solid #1976d2",
            borderRadius: "8px",
            alignContent: "center",
          }}
        >
          <Tooltip
            onClose={() => setTooltipOpen(false)}
            open={tooltipOpen}
            title="Busque por nome ou telefone."
            arrow
          >
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <Search sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <TextField
                placeholder="Buscar"
                variant="standard"
                onClick={() => setTooltipOpen(true)}
                onChange={(ev) => setSearchTerm(ev.target.value)}
                fullWidth
              />
            </Box>
          </Tooltip>
        </Item>
        <Box
          sx={{
            display: "flex",
            alignItems: "stretch",
            justifyContent: "space-between",
          }}
        >
          <Title variant="h6" color="primary">
            Clientes
          </Title>
          {clients && (
            <Typography
              variant="h6"
              sx={{
                fontWeight: 700,
                textTransform: "uppercase",
                letterSpacing: "0.1em",
                lineHeight: 1.5,
                textAlign: "bottom",
              }}
            >
              Total.{" "}
              {Object.values(groupedClients).reduce(
                (total: number, group: any) => total + group.length,
                0
              )}
            </Typography>
          )}
        </Box>
        {Object.values(groupedClients).reduce(
          (total: number, group: any) => total + group.length,
          0
        ) < 1 && (
          <Typography sx={{ textAlign: "center" }} variant="h6">
            Nenhum cliente para mostrar.
          </Typography>
        )}
        {Object.entries(groupedClients)
          .sort(([a], [b]) => a.localeCompare(b))
          .map(([letter, clients]: any, index: any) => (
            <React.Fragment key={index}>
              <Divider sx={{ my: 2 }}>
                <Typography variant="h6" color="GrayText">
                  <strong>{letter}</strong>
                </Typography>
              </Divider>
              {clients.map((client: any) => (
                <Item
                  key={client.phone}
                  onClick={() => navigate(client.phone)}
                  sx={{
                    backgroundColor: "#181818",
                    borderRadius: "20px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 2,
                      overflow: "hidden",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                      <Person sx={{ color: "white" }} />
                      <Typography
                        color="white"
                        variant="h6"
                        sx={{
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          maxWidth: "160px",
                          display: "inline-block",
                        }}
                      >
                        {client.name}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "row",
                        gap: 1,
                      }}
                    >
                      <Chip
                        label={`${client.entries.length} viagens`}
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          color: "white",
                        }}
                      />
                      <ArrowForward sx={{ color: "white" }} />
                    </Box>
                  </Box>
                </Item>
              ))}
            </React.Fragment>
          ))}
      </Box>
    </Box>
  );
}
