import jsPDF from "jspdf";
import dayjs from "dayjs";

import black_logo from "../../../assets/images/black_logo.png";

import "dayjs/locale/pt-br";

dayjs.locale("pt-br");

export const handleDownloadPDF = (booking: any, vehicle: any, driver: any) => {
  const doc = new jsPDF();

  const labelMapping: any = {
    name: "Nome",
    phone: "Telefone",
    flight: "Voo",
    date: "Data e hora",
    passengersQty: "Quantidade de Passageiros",
    vehicle: "Veículo",
    driver: "Motorista",
    origin: "Origem",
    destination: "Destino",
    value: "Valor",
    paidValue: "Valor pago",
    currency: "Moeda",
    paymentMethod: "Forma de pagamento",
    observation: "Observações",
    passengers: "Passageiros",
  };

  const pageHeight = doc.internal.pageSize.height;
  const marginTop = 10;
  const marginBottom = 20;

  const imageWidth = 60;
  const imageHeight = 30;
  const xPosition = (doc.internal.pageSize.width - imageWidth) / 2;

  doc.addImage(black_logo, "PNG", xPosition, 20, imageWidth, imageHeight);

  const text = booking.name;
  const textWidth = doc.getTextWidth(text);
  const xPositionText = (doc.internal.pageSize.width - textWidth - 8) / 2;

  doc.setFontSize(18);
  doc.text(text, xPositionText, 65);

  let yPosition = 80;
  let passengers = [];

  // Gather passengers
  for (let i = 2; i <= 6; i++) {
    const passengerKey = `passenger${i}`;
    if (booking[passengerKey]) {
      passengers.push(booking[passengerKey]);
      delete booking[passengerKey];
    }
  }

  if (passengers.length > 0) {
    passengers.unshift(booking.name);
    booking.passengers = passengers.join(", ");
  }

  doc.setFontSize(12);

  const addText = (label: string, value: string) => {
    const lineHeight = 6;
    const sectionSpacing = 10;

    // Check if there is enough space on the current page
    if (
      yPosition + lineHeight * 2 + sectionSpacing >
      pageHeight - marginBottom
    ) {
      doc.addPage();
      yPosition = marginTop;
    }

    // Add label and value
    doc.setFont("helvetica", "normal");
    doc.text(`${label}:`, 20, yPosition);
    yPosition += lineHeight;

    doc.setFont("helvetica", "bold");
    doc.text(`${value}`, 20, yPosition);
    yPosition += sectionSpacing;
  };

  Object.keys(booking).forEach((key) => {
    const value = booking[key];
    const label = labelMapping[key] || key;

    if (!value) {
      return;
    }

    let displayValue = value;

    // Map specific fields to display names or formats
    if (key === "currency") {
      const currencyMap: any = {
        real: "Reais",
        dollar: "Dólar",
        euro: "Euro",
        pesos: "Pesos",
      };
      displayValue = currencyMap[value] || value;
    }

    if (key === "vehicle" && vehicle) {
      displayValue = `${vehicle.name} (${vehicle.plate}) / ${vehicle.color}`;
    }

    if (key === "date") {
      displayValue = dayjs(booking.date).format("DD/MM/YYYY - HH:mm");
    }

    if (key === "paymentMethod") {
      const paymentMethodMap: any = {
        card: "Cartão",
        cash: "Dinheiro",
        pix: "PIX",
      };
      displayValue = paymentMethodMap[value] || value;
    }

    if (key === "driver" && driver) {
      displayValue = `${driver.name} / ${driver.phone}`;
    }

    addText(label, displayValue);
  });

  // Save the PDF file
  const filename = `reserva-${booking.name}-${dayjs(booking.date).format(
    "DD-MM-YYYY"
  )}`;
  doc.save(filename);
};
