import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router";
import {
  Email,
  EmailOutlined,
  Instagram,
  PhoneOutlined,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import { get, push, ref, remove } from "firebase/database";

import { useNotification } from "../contexts/NotificationContext";
import { database } from "../firebase/firebase";
import white_logo from "../assets/images/logo.png";

export default function CounterScreen({ timeLeft }: any) {
  const [emailExists, setEmailExists] = useState(false);
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { id } = useParams();
  const { showNotification } = useNotification();

  useEffect(() => {
    const storedEmail = localStorage.getItem(`userEmail_${id}`);

    if (storedEmail) {
      setEmail(storedEmail);
      setEmailExists(true);
    }
  }, [id]);

  const totalSeconds = Math.floor(timeLeft / 1000);
  const hours = Math.floor(totalSeconds / 3600)
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((totalSeconds % 3600) / 60)
    .toString()
    .padStart(2, "0");
  const seconds = (totalSeconds % 60).toString().padStart(2, "0");

  const handleNotifyClick = async () => {
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      return showNotification(
        "info",
        "Por favor, insira um endereço de email válido."
      );
    }

    setIsSubmitting(true);

    try {
      const notifyRef = ref(database, `notifyEmail/${id}`);

      await push(notifyRef, { email, id });

      localStorage.setItem(`userEmail_${id}`, email);

      setEmailExists(true);
      showNotification(
        "success",
        "Você receberá um email quando esta página estiver disponível!"
      );
    } catch (error) {
      console.error("Error submitting email:", error);
      showNotification("error", "Ocorreu um erro ao salvar seu email!");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancelNotification = async () => {
    try {
      localStorage.removeItem(`userEmail_${id}`);

      const notifyRef = ref(database, `notifyEmail/${id}`);
      const snapshot = await get(notifyRef);

      snapshot.forEach((childSnapshot) => {
        if (childSnapshot.val().email === email) {
          remove(childSnapshot.ref);
        }
      });

      setEmailExists(false);
      setEmail("");
      showNotification("success", "Notificação cancelada com sucesso!");
    } catch (error) {
      console.error("Error canceling notification:", error);
      showNotification("error", "Ocorreu um erro ao cancelar a notificação!");
    }
  };

  return (
    <Box
      sx={{
        p: 3,
        backgroundColor: "#262626",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Stack spacing={2} sx={{ textAlign: "center", alignItems: "center" }}>
        <Link to="/">
          <img style={{ width: "224px" }} src={white_logo} alt="logo" />
        </Link>
        <Typography variant="h5" color="white">
          Aguarde o dia de sua reserva!
        </Typography>
      </Stack>
      <Stack sx={{ display: "flex", gap: 3, width: "100%" }}>
        <Typography sx={{ textAlign: "center" }} variant="h5" color="GrayText">
          Tempo restante:
        </Typography>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 3,
            justifyContent: "center",
          }}
        >
          <Box sx={{ flex: 1, textAlign: "center", minWidth: "80px" }}>
            <Typography variant="h3" color="white">
              {hours}
            </Typography>
            <Typography variant="h6" color="white">
              Horas
            </Typography>
          </Box>
          <Box sx={{ flex: 1, textAlign: "center", minWidth: "80px" }}>
            <Typography variant="h3" color="white">
              {minutes}
            </Typography>
            <Typography variant="h6" color="white">
              Minutos
            </Typography>
          </Box>
          <Box sx={{ flex: 1, textAlign: "center", minWidth: "80px" }}>
            <Typography variant="h3" color="white">
              {seconds}
            </Typography>
            <Typography variant="h6" color="white">
              Segundos
            </Typography>
          </Box>
        </Stack>
        {!emailExists ? (
          <>
            <TextField
              type="email"
              fullWidth
              value={email}
              placeholder="email@exemplo.com"
              onChange={(e) => setEmail(e.target.value)}
              sx={{ backgroundColor: "white", borderRadius: "4px" }}
            />
            <Button
              variant="contained"
              fullWidth
              onClick={handleNotifyClick}
              disabled={isSubmitting}
              startIcon={<Email />}
            >
              Notificar por Email
            </Button>
          </>
        ) : (
          <>
            <Alert severity="info">
              Enviaremos um email para <strong>{email}</strong> quando esta
              página estiver disponível.
            </Alert>
            <Button
              variant="contained"
              color="error"
              fullWidth
              onClick={handleCancelNotification}
              disabled={isSubmitting}
              startIcon={<Email />}
            >
              Cancelar notificação
            </Button>
          </>
        )}
      </Stack>
      <Stack spacing={2} sx={{ textAlign: "center", alignItems: "center" }}>
        <Link to="/" style={{ textAlign: "center", color: "gray" }}>
          <Typography>Transfer on Floripa</Typography>
        </Link>
        <Stack
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <IconButton href="https://wa.me/5548996523066" sx={{ color: "gray" }}>
            <WhatsAppIcon />
          </IconButton>
          <IconButton
            href="https://www.instagram.com/transfer.onfloripa/"
            sx={{ color: "gray" }}
          >
            <Instagram />
          </IconButton>
          <IconButton
            href="mailto:transfer.onfloripa@gmail.com"
            sx={{ color: "gray" }}
          >
            <EmailOutlined />
          </IconButton>
          <IconButton href="tel://5548996523066" sx={{ color: "gray" }}>
            <PhoneOutlined />
          </IconButton>
        </Stack>
      </Stack>
    </Box>
  );
}
