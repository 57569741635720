import { useState, useCallback } from "react";
import Tesseract from "tesseract.js";

const useImageToText = () => {
  const [ocrResult, setOcrResult] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);

  const extractTextFromImage = useCallback((file: File) => {
    setLoading(true);
    setOcrResult("");
    setProgress(0);
    setError(null);

    const reader = new FileReader();

    reader.onload = () => {
      Tesseract.recognize(reader.result as string, "eng", {
        logger: (info) => {
          if (info.status === "recognizing text") {
            setProgress(Math.round(info.progress * 100));
          }
        },
      })
        .then(({ data: { text } }) => {
          setOcrResult(text);
        })
        .catch((err) => {
          console.error("OCR Error:", err);
          setError("Failed to process the image.");
        })
        .finally(() => {
          setLoading(false);
        });
    };
    reader.onerror = () => {
      setError("Failed to read the file.");
      setLoading(false);
    };
    reader.readAsDataURL(file);
  }, []);

  return {
    ocrResult,
    loading,
    progress,
    error,
    extractTextFromImage,
  };
};

export default useImageToText;
