import React from "react";
import { Button, Collapse } from "@mui/material";
import { WhatsApp, AirplaneTicket } from "@mui/icons-material";

interface Props {
  booking: any;
  expanded: boolean;
}

export function BookingActions({ booking, expanded }: Props) {
  const generateWhatsAppLink = (phoneNumber: string) => {
    const cleanNumber = phoneNumber.toString().replace(/\D/g, "");

    return window.open(`https://wa.me/${cleanNumber}`);
  };

  return (
    <Collapse in={expanded} timeout="auto" unmountOnExit>
      {booking.flight && (
        <Button
          href={`https://www.flightradar24.com/${booking.flight}`}
          target="_blank"
          fullWidth
          variant="contained"
          color="primary"
          startIcon={<AirplaneTicket />}
          sx={{ mb: 1 }}
        >
          Acompanhar voo
        </Button>
      )}
      {booking.phone && (
        <>
          <Button
            onClick={() => generateWhatsAppLink(booking.phone)}
            fullWidth
            variant="contained"
            startIcon={<WhatsApp />}
            sx={{
              backgroundColor: "#25d366 !important",
              color: "white",
            }}
          >
            WhatsApp
          </Button>
        </>
      )}
    </Collapse>
  );
}
