import { Typography } from "@mui/material";
import { styled } from "@mui/styles";

export const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  textTransform: "uppercase",
  letterSpacing: "0.1em",
  lineHeight: 1.5,
  mb: 1,
}));
