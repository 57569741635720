import React from "react";
import { Box, Stack, Typography } from "@mui/material";

import mercosul from "../assets/images/mercosul.png";

export default function BrazilianPlate({ plate }: any) {
  return (
    <Box sx={{ border: "3px solid black", borderRadius: "8px" }}>
      <Box sx={{ backgroundColor: "#003399" }}>
        <Stack
          justifyContent="space-between"
          display="flex"
          alignItems="center"
          flexDirection="row"
          p="2px 4px"
        >
          <img
            style={{
              width: 24,
            }}
            src={mercosul}
            alt="mercosul"
          />
          <Typography
            sx={{
              textTransform: "uppercase",
              fontWeight: "bold",
              color: "white",
            }}
            variant="caption"
          >
            Brasil
          </Typography>
          <img
            style={{
              width: 16,
              border: "1px solid white",
              borderRadius: "3px",
            }}
            src={
              "https://s1.static.brasilescola.uol.com.br/be/conteudo/images/2-bandeira-do-brasil.jpg"
            }
            alt="brazil"
          />
        </Stack>
      </Box>
      <Typography
        variant="h6"
        sx={{ p: "3px", fontWeight: "bold", textAlign: "center" }}
      >
        {plate}
      </Typography>
    </Box>
  );
}
