import React, { useState } from "react";
import { Item } from "./settings/Item";
import { Box, Divider, IconButton, TextField, Tooltip } from "@mui/material";
import { Article, Search, TableChart } from "@mui/icons-material";

interface Props {
  onSearchChange: (value: string) => any;
  onVisualizationChange: (value: string) => any;
  active: string;
  searchBarDisabled: boolean;
}

export default function Filters({
  onSearchChange,
  onVisualizationChange,
  searchBarDisabled,
  active,
}: Props) {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "stretch",
        justifyContent: "space-between",
      }}
    >
      <Item
        elevation={1}
        sx={{
          p: "8px !important",
          backgroundColor: "#e3f2fd",
          border: "1px solid #1976d2",
          borderRadius: "8px",
          alignContent: "center",
        }}
      >
        <Tooltip
          onClose={() => setTooltipOpen(false)}
          open={tooltipOpen}
          title="Busque por nome, telefone, origem ou destino."
          arrow
        >
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <Search sx={{ color: "action.active", mr: 1, my: 0.5 }} />
            <TextField
              disabled={searchBarDisabled}
              placeholder="Buscar"
              variant="standard"
              onClick={() => setTooltipOpen(true)}
              onChange={(ev) => onSearchChange(ev.target.value)}
            />
          </Box>
        </Tooltip>
      </Item>
      <Item
        elevation={1}
        sx={{
          p: "8px !important",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          backgroundColor: "#e3f2fd",
          border: "1px solid #1976d2",
          borderRadius: "8px",
        }}
      >
        <IconButton
          color={active === "card" ? "primary" : "inherit"}
          onClick={() => onVisualizationChange("card")}
        >
          <Article />
        </IconButton>
        <Divider orientation="vertical" variant="middle" flexItem />
        <IconButton
          color={active === "table" ? "primary" : "inherit"}
          onClick={() => onVisualizationChange("table")}
        >
          <TableChart />
        </IconButton>
      </Item>
    </Box>
  );
}
