import React, { useEffect, useState } from "react";
import { Box, Typography, Avatar } from "@mui/material";

import dayjs from "dayjs";

import "dayjs/locale/pt-br";

dayjs.locale("pt-br");

interface Props {
  booking: any;
  driver: any;
  vehicle: any;
}

export function BookingDetails({ booking, driver, vehicle }: Props) {
  const [passengersName, setPassengersName] = useState<any>();
  const [multipliesCurrency, setMultipliesCurrency] = useState(1);
  const [currency, setCurrency] = useState("R$");
  const [paymentMethod, setPaymentMethod] = useState("Dinheiro");

  useEffect(() => {
    if (booking.passengersQty && booking.passengersQty >= 2) {
      const passengers = [];

      passengers.push(booking.name);

      for (let i = 2; i <= booking.passengersQty; i++) {
        passengers.push(booking[`passenger${i}`]);
      }

      setPassengersName(passengers.join(", ").toString());
    }
  }, [booking]);

  useEffect(() => {
    if (booking.currency) {
      switch (booking.currency) {
        case "dollar":
          setCurrency("$");
          setMultipliesCurrency(0.16);
          break;
        case "euro":
          setCurrency("€");
          setMultipliesCurrency(0.16);
          break;
        case "pesos":
          setCurrency("₱");
          setMultipliesCurrency(166.21);
          break;
        case "real":
        default:
          setCurrency("R$");
          setMultipliesCurrency(1);
          break;
      }
    }
  }, [booking.currency]);

  useEffect(() => {
    switch (booking.paymentMethod) {
      case "pix":
        setPaymentMethod("PIX");
        break;
      case "card":
        setPaymentMethod("Cartão");
        break;
      case "cash":
      default:
        setPaymentMethod("Dinheiro");
        break;
    }
  }, [booking.paymentMethod]);

  const convertedValue = (booking.value / multipliesCurrency).toFixed(0);

  return (
    <Box>
      <Typography variant="h5" sx={{ color: "#34d399" }}>
        {currency} {Number(booking.value)}
      </Typography>
      {booking.currency !== "real" && (
        <Typography variant="body1" sx={{ opacity: 0.5, paddingLeft: 1 }}>
          (R$ ~{convertedValue})
        </Typography>
      )}
      <Typography variant="body1">
        Meio de pagamento: <strong>{paymentMethod}</strong>
      </Typography>
      {booking.phone && (
        <Typography variant="body1">
          Telefone: <strong>{booking.phone}</strong>
        </Typography>
      )}
      {driver && (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography>Motorista:</Typography>
          <Avatar src={driver.photo} sx={{ width: 24, height: 24 }} />
          <Typography>
            <strong>{driver.name}</strong>
          </Typography>
        </Box>
      )}
      {vehicle && (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography>Veículo:</Typography>
          <Avatar src={vehicle.photo} sx={{ width: 24, height: 24 }} />
          <Typography>
            <strong>
              {vehicle.name} ({vehicle.plate})
            </strong>
          </Typography>
        </Box>
      )}
      <Typography variant="body1">
        Qtd. Passageiros: <strong>{booking.passengersQty}</strong>
      </Typography>
      {passengersName && (
        <Typography>
          Passageiros: <strong>{passengersName}</strong>
        </Typography>
      )}
      <Typography variant="body1">
        Origem: <strong>{booking.origin}</strong>
      </Typography>
      <Typography variant="body1">
        Destino: <strong>{booking.destination}</strong>
      </Typography>
      {booking.flight && (
        <Typography variant="body1">
          Voo: <strong>{booking.flight}</strong>
        </Typography>
      )}
      <Typography variant="body1">
        Data: <strong>{dayjs(booking.date).format("DD/MM/YYYY")}</strong>
      </Typography>
      <Typography variant="body1">
        Horário: <strong>{dayjs(booking.date).format("HH:mm")}</strong>
      </Typography>
      {booking.observation && (
        <Typography variant="body1">
          Observações: <strong>{booking.observation}</strong>
        </Typography>
      )}
    </Box>
  );
}
