import React from "react";
import {
  Modal,
  Box,
  Typography,
  Divider,
  ButtonGroup,
  Button,
} from "@mui/material";
import { Delete } from "@mui/icons-material";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 280,
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
  p: 3,
};

interface Props {
  open: boolean;
  handleClose: () => void;
  handleDelete: () => void;
}

export function BookingModal({ open, handleClose, handleDelete }: Props) {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Typography variant="h6" sx={{ fontWeight: "bold", pb: 1 }}>
          Excluir
        </Typography>
        <Divider />
        <Typography variant="body1" sx={{ py: 1 }}>
          Você tem certeza que deseja excluir essa reserva?
        </Typography>
        <ButtonGroup fullWidth>
          <Button startIcon={<Delete />} color="error" onClick={handleDelete}>
            Excluir
          </Button>
          <Button color="primary" onClick={handleClose} variant="contained">
            Cancelar
          </Button>
        </ButtonGroup>
      </Box>
    </Modal>
  );
}
