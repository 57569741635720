import React, { useEffect, useState } from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/styles";
import { SimCardDownload } from "@mui/icons-material";

import { get, ref } from "firebase/database";
import dayjs from "dayjs";

import { database } from "../../../firebase/firebase";
import { useNotification } from "../../../contexts/NotificationContext";

import "dayjs/locale/pt-br";

dayjs.locale("pt-br");

const StyledTableCell = styled(TableCell)(({ theme }: any) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#181818",
    color: "white",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#e4e4e4",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface Props {
  rawData: any;
}

export default function BookingTable({ rawData }: Props) {
  const [drivers, setDrivers] = useState<any>({});
  const [vehicles, setVehicles] = useState<any>({});

  const { showNotification } = useNotification();

  useEffect(() => {
    const driverIds = rawData
      .map((booking: any) => booking.driver)
      .filter(
        (driver: any, index: number, self: any) =>
          self.indexOf(driver) === index
      );

    driverIds.forEach((driverId: string) => {
      get(ref(database, `drivers/${driverId}`)).then((snapshot: any) => {
        if (snapshot.exists()) {
          setDrivers((prevDrivers: any) => ({
            ...prevDrivers,
            [driverId]: snapshot.val(),
          }));
        }
      });
    });

    const vehicleIds = rawData
      .map((booking: any) => booking.vehicle)
      .filter(
        (vehicle: any, index: number, self: any) =>
          self.indexOf(vehicle) === index
      );

    vehicleIds.forEach((vehicleId: string) => {
      get(ref(database, `vehicles/${vehicleId}`)).then((snapshot: any) => {
        if (snapshot.exists()) {
          setVehicles((prevVehicles: any) => ({
            ...prevVehicles,
            [vehicleId]: snapshot.val(),
          }));
        }
      });
    });
  }, [rawData]);

  const exportToCSV = () => {
    const headers = [
      "Data",
      "Nome",
      "Valor",
      "Meio de pagamento",
      "Telefone",
      "Motorista",
      "Veículo",
      "Qtd. Passageiros",
      "Passageiros",
      "Origem",
      "Destino",
      "Voo",
      "Horário",
      "Observações",
    ];

    showNotification("info", "Baixando CSV...");

    const rows = rawData.map((booking: any) => {
      const driver = drivers[booking.driver];
      const vehicle = vehicles[booking.vehicle];

      let currency = "R$";
      let currencyMultiplier = 1;

      switch (booking.currency) {
        case "dollar":
          currency = "$";
          currencyMultiplier = 0.16;
          break;
        case "euro":
          currency = "€";
          currencyMultiplier = 0.16;
          break;
        case "pesos":
          currency = "₱";
          currencyMultiplier = 166.21;
          break;
      }
      const convertedValue =
        booking.value * currencyMultiplier || booking.value;

      let paymentMethod = "Dinheiro";
      switch (booking.paymentMethod) {
        case "pix":
          paymentMethod = "PIX";
          break;
        case "card":
          paymentMethod = "Cartão";
          break;
      }

      let passengersName = booking.name || "";

      if (booking.passengersQty > 1) {
        const additionalPassengers = [];

        for (let i = 2; i <= booking.passengersQty; i++) {
          if (booking[`passenger${i}`]) {
            additionalPassengers.push(booking[`passenger${i}`]);
          }
        }

        passengersName += ` / ${additionalPassengers.join(" / ")}`;
      }

      const sanitize = (value: any) =>
        value !== undefined && value !== null ? String(value).trim() : "N/A";

      return [
        sanitize(dayjs(booking.date).format("DD/MM/YYYY")),
        sanitize(booking.name),
        sanitize(`${currency} ${convertedValue}`),
        sanitize(paymentMethod),
        sanitize(booking.phone),
        sanitize(driver ? driver.name : "N/A"),
        sanitize(vehicle ? `${vehicle.name} (${vehicle.plate})` : "N/A"),
        sanitize(booking.passengersQty),
        sanitize(passengersName),
        sanitize(booking.origin),
        sanitize(booking.destination),
        sanitize(booking.flight || "N/A"),
        sanitize(dayjs(booking.date).format("HH:mm")),
        sanitize(booking.observation || "N/A"),
      ];
    });

    const csvContent = [headers, ...rows]
      .map((row) =>
        row.map((cell: any) => `"${cell.replace(/"/g, '""')}"`).join(",")
      )
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `agendamentos-${dayjs().format("DD/MM/YYYY")}.csv`
    );

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    showNotification("success", "Arquivo CSV baixado com sucesso!");
  };

  return (
    <>
      <Button
        startIcon={<SimCardDownload />}
        sx={{ mb: 3 }}
        color="primary"
        variant="contained"
        fullWidth
        onClick={exportToCSV}
      >
        Exportar CSV
      </Button>
      <TableContainer component={Paper} sx={{ borderRadius: "12px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Data</StyledTableCell>
              <StyledTableCell align="left">Nome</StyledTableCell>
              <StyledTableCell align="left">Valor</StyledTableCell>
              <StyledTableCell align="left">Meio de pagamento</StyledTableCell>
              <StyledTableCell align="left">Telefone</StyledTableCell>
              <StyledTableCell align="left">Motorista</StyledTableCell>
              <StyledTableCell align="left">Veículo</StyledTableCell>
              <StyledTableCell align="left">Qtd. Passageiros</StyledTableCell>
              <StyledTableCell align="left">Passageiros</StyledTableCell>
              <StyledTableCell align="left">Origem</StyledTableCell>
              <StyledTableCell align="left">Destino</StyledTableCell>
              <StyledTableCell align="left">Voo</StyledTableCell>
              <StyledTableCell align="left">Horário</StyledTableCell>
              <StyledTableCell align="left">Observações</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rawData.map((booking: any, index: number) => {
              const driver = drivers[booking.driver];
              const vehicle = vehicles[booking.vehicle];

              let currency = "R$";
              let currencyMultiplier = 1;

              switch (booking.currency) {
                case "dollar":
                  currency = "$";
                  currencyMultiplier = 0.16;
                  break;
                case "euro":
                  currency = "€";
                  currencyMultiplier = 0.16;
                  break;
                case "pesos":
                  currency = "₱";
                  currencyMultiplier = 166.21;
                  break;
              }
              const convertedValue =
                booking.value * currencyMultiplier || booking.value;

              let paymentMethod = "Dinheiro";

              switch (booking.paymentMethod) {
                case "pix":
                  paymentMethod = "PIX";
                  break;
                case "card":
                  paymentMethod = "Cartão";
                  break;
              }

              let passengersName = booking.name;

              if (booking.passengersQty > 1) {
                const additionalPassengers = [];

                for (let i = 2; i <= booking.passengersQty; i++) {
                  if (booking[`passenger${i}`]) {
                    additionalPassengers.push(booking[`passenger${i}`]);
                  }
                }

                passengersName += `, ${additionalPassengers.join(", ")}`;
              }

              return (
                <StyledTableRow key={index}>
                  <TableCell align="left">
                    {dayjs(booking.date).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell align="left">{booking.name}</TableCell>
                  <TableCell align="left">
                    {currency} {convertedValue}
                  </TableCell>
                  <TableCell align="left">{paymentMethod}</TableCell>
                  <TableCell align="left">{booking.phone}</TableCell>
                  {driver ? (
                    <TableCell align="left">{driver.name}</TableCell>
                  ) : (
                    <TableCell align="left">N/A</TableCell>
                  )}
                  {vehicle ? (
                    <TableCell align="left">
                      {vehicle.name} ({vehicle.plate})
                    </TableCell>
                  ) : (
                    <TableCell align="left">N/A</TableCell>
                  )}
                  <TableCell align="left">{booking.passengersQty}</TableCell>
                  <TableCell align="left">{passengersName}</TableCell>
                  <TableCell align="left">{booking.origin}</TableCell>
                  <TableCell align="left">{booking.destination}</TableCell>
                  {booking.flight ? (
                    <TableCell align="left">{booking.flight}</TableCell>
                  ) : (
                    <TableCell align="left">N/A</TableCell>
                  )}
                  <TableCell align="left">
                    {dayjs(booking.date).format("HH:mm")}
                  </TableCell>
                  {booking.observation ? (
                    <TableCell align="left">{booking.observation}</TableCell>
                  ) : (
                    <TableCell align="left">N/A</TableCell>
                  )}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
