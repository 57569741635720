import React from "react";
import { Box, LinearProgress, Stack, Typography } from "@mui/material";

import logo from "../assets/images/black_logo.png";

export default function LoadingScreen() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        textAlign: "center",
        flexDirection: "column",
      }}
    >
      <Stack spacing={2}>
        <img style={{ width: "180px" }} src={logo} alt="logo" />
        <Typography variant="h5">Carregando...</Typography>
        <LinearProgress color="primary" />
      </Stack>
    </Box>
  );
}
