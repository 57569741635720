import React, { useEffect, useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { NotificationsOff } from "@mui/icons-material";

import { ref, remove, set } from "firebase/database";
import { deleteToken, getToken } from "firebase/messaging";

import { Item } from "./Item";
import { Title } from "./Title";

import { database, messaging } from "../../../firebase/firebase";

import { useAuth } from "../../../contexts/AuthContext";
import { useNotification } from "../../../contexts/NotificationContext";

export default function Notifications() {
  const [permission, setPermission] = useState(Notification.permission);
  const [isTokenRevoked, setIsTokenRevoked] = useState(false);

  const { user } = useAuth();

  const { showNotification } = useNotification();

  useEffect(() => {
    if (permission === "granted" && !isTokenRevoked) {
      getToken(messaging, {
        vapidKey:
          "BDH5wygb9eIU9-z35Gb3FB5pLzcgFM92trroPNtDf5TuokVt0neODRj0i2nT601R0GW3WVLSvn2byLyWqGKjeks",
      })
        .then((token) => {
          if (!token) setIsTokenRevoked(true);
        })
        .catch(() => {
          setIsTokenRevoked(true);
        });
    }
  }, [permission, isTokenRevoked]);

  const getDeviceType = () => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (/mobile/i.test(userAgent)) {
      return "mobile";
    }

    if (/tablet/i.test(userAgent)) {
      return "tablet";
    }

    return "desktop";
  };

  const storeToken = async (token: string) => {
    const deviceType = getDeviceType();

    try {
      await set(
        ref(database, `subscriptions/${user.uid}/${deviceType}`),
        token
      );
    } catch (err) {
      showNotification("error", "Erro ao salvar o token no banco de dados.");
    }
  };

  const handleRequestNotificationPermission = async () => {
    try {
      if (permission === "default") {
        const result = await Notification.requestPermission();

        setPermission(result);

        if (result === "granted") {
          const token = await getToken(messaging, {
            vapidKey:
              "BDH5wygb9eIU9-z35Gb3FB5pLzcgFM92trroPNtDf5TuokVt0neODRj0i2nT601R0GW3WVLSvn2byLyWqGKjeks",
          });

          await storeToken(token);

          return showNotification(
            "success",
            "Notificações ativadas com sucesso!"
          );
        } else {
          return showNotification(
            "warning",
            "Notificações negada. Vá nos ajustes do seu celular para conceder permissão."
          );
        }
      }
    } catch (err) {
      return showNotification(
        "error",
        "Seu navegador não suporta notificações push."
      );
    }
  };

  const handleRevokeToken = () => {
    try {
      deleteToken(messaging)
        .then(() => {
          const device = getDeviceType();

          remove(ref(database, `subscriptions/${user.uid}/${device}`))
            .then(() => {
              setIsTokenRevoked(true);

              showNotification(
                "info",
                "Token revogado e removido da base de dados."
              );
            })
            .catch(() => {
              showNotification(
                "error",
                "Erro ao remover o token da base de dados."
              );
            });
        })
        .catch(() => {
          showNotification("error", "Erro ao revogar o token.");
        });
    } catch (err) {
      showNotification("error", "Erro ao revogar o token.");
    }
  };

  const handleReenableNotifications = async () => {
    try {
      await deleteToken(messaging);

      const token = await getToken(messaging, {
        vapidKey:
          "BDH5wygb9eIU9-z35Gb3FB5pLzcgFM92trroPNtDf5TuokVt0neODRj0i2nT601R0GW3WVLSvn2byLyWqGKjeks",
      });

      await storeToken(token);

      setIsTokenRevoked(false);

      showNotification("success", "Notificações habilitadas com sucesso!");
    } catch (err) {
      showNotification("error", "Erro ao tentar gerar um novo token.");
    }
  };

  return (
    <>
      <Title variant="h6" color="primary">
        Notificações
      </Title>
      <Item elevation={3}>
        <Stack spacing={1}>
          {permission === "granted" && !isTokenRevoked && (
            <>
              <Typography variant="body1">
                Se você desativar as notificações você não receberá mais alertas
                sobre suas próximas viagens, mas ainda é possível ativar
                novamente.
              </Typography>
              <Button
                variant="contained"
                color="error"
                fullWidth
                startIcon={<NotificationsOff />}
                onClick={handleRevokeToken}
              >
                Desativar notificações
              </Button>
            </>
          )}
          {permission === "granted" && isTokenRevoked && (
            <>
              <Typography variant="body1">
                As notificações foram desativadas. Você pode habilitá-las
                novamente.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                startIcon={<Notifications />}
                onClick={handleReenableNotifications}
              >
                Habilitar notificações
              </Button>
            </>
          )}
          {permission === "default" && (
            <>
              <Typography variant="body1">
                Ative as notificações para receber alertas sobre as próximas
                viagens.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                startIcon={<Notifications />}
                onClick={handleRequestNotificationPermission}
              >
                Habilitar notificações
              </Button>
            </>
          )}
          {permission === "denied" && (
            <>
              <Typography variant="body1">
                As notificações foram bloqueadas para este dispositivo. Para
                ativar as notificações, vá nos ajustes do seu celular.
              </Typography>
            </>
          )}
        </Stack>
      </Item>
    </>
  );
}
