import * as React from "react";
import { useNavigate } from "react-router";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AutoAwesome, PersonAddAlt1 } from "@mui/icons-material";
import { Divider } from "@mui/material";

interface Props {
  handleAdd: () => void;
}

export default function ButtonAppBar({ handleAdd }: Props) {
  const navigate = useNavigate();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: "#181818" }}>
        <Toolbar>
          <IconButton
            onClick={() => {
              return navigate("/");
            }}
            size="large"
            edge="start"
            aria-label="menu"
            color="inherit"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Painel
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <IconButton onClick={() => navigate("ia-add")} color="inherit">
              <AutoAwesome />
            </IconButton>
            <Divider
              sx={{ borderColor: "rgba(255, 255, 255, 0.12)" }}
              orientation="vertical"
              variant="middle"
              flexItem
            />
            <IconButton onClick={handleAdd} color="inherit">
              <PersonAddAlt1 />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
