import React from "react";
import ReactDOM from "react-dom";

import Router from "./Router";

import { AuthProvider } from "./contexts/AuthContext";
import { NotificationProvider } from "./contexts/NotificationContext";

import "./firebase/firebase";

import "./i18n";
import "./reset.css";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/service-worker.js")
    .then((registration) => {
      console.log("Service Worker registered with scope:", registration.scope);
    })
    .catch((error) => {
      console.error("Service Worker registration failed:", error);
    });

  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {
      console.log("Service Worker registered with scope:", registration.scope);
    })
    .catch((error) => {
      console.error("Service Worker registration failed:", error);
    });
}

ReactDOM.render(
  <AuthProvider>
    <NotificationProvider>
      <Router />
    </NotificationProvider>
  </AuthProvider>,
  document.getElementById("root")
);
