import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router";

import {
  Avatar,
  Box,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { EmailOutlined, Instagram, PhoneOutlined } from "@mui/icons-material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { styled } from "@mui/styles";

import dayjs from "dayjs";

import { get, ref } from "firebase/database";

import { database } from "../firebase/firebase";
import LoadingScreen from "../utils/LoadingScreen";
import white_logo from "../assets/images/logo.png";
import BrazilianPlate from "../components/BrazilianPlate";
import CounterSreen from "../screens/CounterScreen";

const Wrapper = styled(Paper)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  textAlign: "center",
}));

export default function CheckinPage() {
  const [booking, setBooking] = useState<any>();
  const [driver, setDriver] = useState<any>();
  const [vehicle, setVehicle] = useState<any>();
  const [timeLeft, setTimeLeft] = useState<number>(0);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    get(ref(database, `booking/${id}`)).then((snapshot) => {
      if (snapshot.exists()) {
        setBooking(snapshot.val());
      } else {
        return navigate("/");
      }
    });
  }, [id, navigate]);

  useEffect(() => {
    if (!booking) return;

    if (booking.driver) {
      get(ref(database, `drivers/${booking.driver}`)).then((snapshot: any) => {
        if (snapshot.exists()) {
          setDriver(snapshot.val());
        } else {
          return navigate("/");
        }
      });
    } else {
      navigate("/");
    }
  }, [booking, navigate]);

  useEffect(() => {
    if (!booking) return;

    if (booking.vehicle) {
      get(ref(database, `vehicles/${booking.vehicle}`)).then(
        (snapshot: any) => {
          if (snapshot.exists()) {
            setVehicle(snapshot.val());
          } else {
            return navigate("/");
          }
        }
      );
    } else {
      navigate("/");
    }
  }, [booking, navigate]);

  useEffect(() => {
    if (!booking) return;

    const checkDate = () => {
      const currentDate = dayjs();
      const bookingDate = dayjs(booking.date);
      const isSameDay = currentDate.isSame(bookingDate, "day");

      if (!isSameDay) {
        const diff = bookingDate.startOf("day").diff(currentDate);

        setTimeLeft(diff > 0 ? diff : 0);
      } else {
        setTimeLeft(0);
      }
    };

    checkDate();
    const interval = setInterval(checkDate, 1000);

    return () => clearInterval(interval);
  }, [booking]);

  if (!booking || !driver || !vehicle) {
    return <LoadingScreen />;
  }

  if (timeLeft > 0) {
    return <CounterSreen timeLeft={timeLeft} />;
  }

  return (
    booking && (
      <Box sx={{ p: 3, backgroundColor: "#262626", minHeight: "100%" }}>
        <Stack spacing={3}>
          <span style={{ display: "flex", justifyContent: "center" }}>
            <Link to="/">
              <img style={{ width: "224px" }} src={white_logo} alt="logo" />
            </Link>
          </span>

          <Stack spacing={2} sx={{ textAlign: "center" }}>
            <Typography variant="h5" color="white">
              Instruções de embarque
            </Typography>
            <Typography variant="h6" color="GrayText">
              <strong>{booking.name}</strong>, confira as informações sobre seu
              motorista e o veículo que será utilizado para sua reserva no dia{" "}
              <strong>{dayjs(booking.date).format("DD/MM/YYYY, HH:mm")}</strong>
            </Typography>
          </Stack>
          <Wrapper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
            <Stack spacing={2}>
              <Typography variant="h5">
                <strong>Motorista</strong>
              </Typography>
              <Avatar
                sx={{ width: 96, height: 96, alignSelf: "center" }}
                src={driver.photo}
              />
              <Typography variant="h5">{driver.name}</Typography>
            </Stack>
          </Wrapper>
          <Wrapper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
            <Stack spacing={2}>
              <Typography variant="h5">
                <strong>Veículo</strong>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  gap: 3,
                }}
              >
                <Avatar
                  variant="rounded"
                  sx={{
                    width: 128,
                    height: 128,
                  }}
                  src={vehicle.photo}
                />
                <Box
                  sx={{
                    textAlign: "left",
                  }}
                >
                  <Typography variant="h5">{vehicle.name}</Typography>
                  <Typography variant="h6" color="GrayText">
                    {vehicle.color}
                  </Typography>
                  <BrazilianPlate plate={vehicle.plate} />
                </Box>
              </Box>
            </Stack>
          </Wrapper>
          <Stack>
            <Link to="/" style={{ textAlign: "center", color: "gray" }}>
              <Typography>Transfer on Floripa</Typography>
            </Link>
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <IconButton
                href="https://wa.me/5548996523066"
                sx={{ color: "gray" }}
              >
                <WhatsAppIcon />
              </IconButton>
              <IconButton
                href="https://www.instagram.com/transfer.onfloripa/"
                sx={{ color: "gray" }}
              >
                <Instagram />
              </IconButton>
              <IconButton
                href="mailto:transfer.onfloripa@gmail.com"
                sx={{ color: "gray" }}
              >
                <EmailOutlined />
              </IconButton>
              <IconButton href="tel://5548996523066" sx={{ color: "gray" }}>
                <PhoneOutlined />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    )
  );
}
