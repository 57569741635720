import React from "react";
import { useNavigate } from "react-router";
import { Box, IconButton, Toolbar, Typography, AppBar } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Drivers from "../components/settings/Drivers";

export default function DriversPage() {
  const navigate = useNavigate();

  const goBack = () => navigate("/admin-management");

  return (
    <Box>
      <AppBar position="static" sx={{ backgroundColor: "#181818" }}>
        <Toolbar>
          <IconButton
            onClick={goBack}
            size="large"
            edge="start"
            aria-label="menu"
            color="inherit"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Motoristas
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 2 }}>
        <Drivers />
      </Box>
    </Box>
  );
}
