import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { Box, Stack, Typography } from "@mui/material";

import dayjs from "dayjs";

import {
  endAt,
  get,
  orderByChild,
  query,
  ref,
  startAt,
} from "firebase/database";
import { onMessage } from "firebase/messaging";

import ButtonAppBar from "./components/AppBar";
import BookingCard from "./components/booking/BookingCard";
import PanelNavigation from "./components/PanelNavigation";
import Modal from "../components/Modal";

import { database, messaging } from "../firebase/firebase";
import { useNotification } from "../contexts/NotificationContext";

import OthersScreen from "./screens/OthersScreen";
import LoadingScreen from "../utils/LoadingScreen";
import Filters from "./components/Filters";
import BookingTable from "./components/booking/BookingTable";

dayjs.locale("pt-br");

export default function AdminDashboardPage() {
  const [phoneNumber, setPhoneNumber] = useState<any>();
  const [lastClipboardText, setLastClipboardText] = useState<string | null>(
    null
  );
  const [bottomNavigation, setBottomNavigation] = useState(2);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[]>();
  const [filteredData, setFilteredData] = useState<any[]>();
  const [rawData, setRawData] = useState<any[]>();
  const [dataLength, setDataLength] = useState(0);
  const [visualization, setVisualization] = useState<string>("card");
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();
  const { showNotification } = useNotification();

  const readFromClipboard = useCallback(async () => {
    try {
      const text = await navigator.clipboard.readText();

      if (text !== lastClipboardText) {
        setLastClipboardText(text);

        const phoneNumberRegex =
          /\(?\+[0-9]{1,3}\)? ?-?[0-9]{1,3} ?-?[0-9]{3,5} ?-?[0-9]{4}( ?-?[0-9]{3})? ?(\w{1,10}\s?\d{1,6})?/;

        if (phoneNumberRegex.test(text)) {
          setPhoneNumber(text);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, [lastClipboardText]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      readFromClipboard();
    }, 2000);

    return () => clearInterval(intervalId);
  }, [readFromClipboard]);

  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload: any) => {
      showNotification(
        "info",
        payload.notification?.body,
        payload.notification?.title
      );
    });

    return () => unsubscribe();
  }, [showNotification]);

  useEffect(() => {
    let firebaseQuery;

    if (bottomNavigation === 1) {
      firebaseQuery = query(
        ref(database, "booking"),
        orderByChild("date"),
        endAt(dayjs().valueOf())
      );
    } else if (bottomNavigation === 2) {
      firebaseQuery = query(
        ref(database, "booking"),
        orderByChild("date"),
        startAt(dayjs().valueOf())
      );
    } else {
      return;
    }

    get(firebaseQuery).then((snapshot) => {
      if (snapshot.exists()) {
        setLoading(false);

        const booking = snapshot.val();

        const bookingArray = Object.entries(booking).map(
          ([id, value]: any) => ({
            id,
            ...value,
          })
        );

        setData(snapshot.val());
        setRawData(bookingArray);

        const newFilteredData = searchQuery
          ? applySearchFilter(bookingArray, searchQuery)
          : bookingArray;

        const groupedBookings: any = groupAndSortBookings(
          newFilteredData,
          bottomNavigation
        );

        const totalBookings: any = Object.values(groupedBookings).reduce(
          (total: any, group: any) => total + group.length,
          0
        );

        setFilteredData(groupedBookings);
        setDataLength(totalBookings);
      }
    });
  }, [bottomNavigation, searchQuery, showNotification]);

  const handleOpen = () => {
    navigate("add");
  };

  const handleSearchBarChange = (value: string) => {
    setSearchQuery(value);
  };

  const applySearchFilter = (data: any[], query: string) => {
    return data.filter((item: any) =>
      ["origin", "destination", "phone", "name"].some((key) => {
        const value = item[key];
        return (
          typeof value === "string" &&
          value.toLowerCase().includes(query.toLowerCase())
        );
      })
    );
  };

  const groupAndSortBookings = (bookings: any[], navigation: number) => {
    const groupedBookings = bookings.reduce((acc, booking) => {
      const date = dayjs(booking.date).format("YYYY-MM-DD");

      if (!acc[date]) {
        acc[date] = [];
      }

      acc[date].push(booking);

      return acc;
    }, {});

    Object.keys(groupedBookings).forEach((date) => {
      groupedBookings[date].sort((a: any, b: any) => {
        return dayjs(a.date).valueOf() - dayjs(b.date).valueOf();
      });
    });

    return Object.fromEntries(
      Object.entries(groupedBookings).sort(([dateA], [dateB]) =>
        navigation === 1
          ? new Date(dateB).getTime() - new Date(dateA).getTime()
          : new Date(dateA).getTime() - new Date(dateB).getTime()
      )
    );
  };

  const handleOpenWithNumber = (number: string) => {
    const cleanNumber = number.replace(/\D/g, "");

    navigate(`add?number=${cleanNumber}`);
  };

  const formatDate = (date: any) => {
    if (dayjs(date).isSame(dayjs(), "day")) {
      return "HOJE";
    }

    return dayjs(date).format("DD MMMM YYYY");
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Box sx={{ pb: 7, minHeight: "100%" }}>
      <ButtonAppBar handleAdd={handleOpen} />
      {!loading && bottomNavigation === 0 && (
        <Box sx={{ p: 2 }}>
          <OthersScreen />
        </Box>
      )}
      {!loading && bottomNavigation !== 0 && (
        <Box sx={{ p: 2 }}>
          <Filters
            active={visualization}
            searchBarDisabled={visualization === "table"}
            onVisualizationChange={setVisualization}
            onSearchChange={handleSearchBarChange}
          />
          {filteredData &&
            visualization === "card" &&
            Object.entries(filteredData).map(
              ([date, bookings]: [string, any[]]) => (
                <Box key={date} sx={{ mb: 4 }}>
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      mb: 1,
                    }}
                  >
                    <Typography
                      variant="h6"
                      color={"primary"}
                      sx={{
                        fontWeight: 700, // Make the text bold for emphasis
                        textTransform: "uppercase", // Makes the date stand out
                        letterSpacing: "0.1em", // Adds spacing between letters for clarity
                        lineHeight: 1.5, // Improves readability
                        textAlign: "center", // Centers the text
                      }}
                    >
                      {formatDate(date)}
                    </Typography>
                    {bookings.length >= 2 && (
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 700, // Make the text bold for emphasis
                          textTransform: "uppercase", // Makes the date stand out
                          letterSpacing: "0.1em", // Adds spacing between letters for clarity
                          lineHeight: 1.5, // Improves readability
                          textAlign: "center", // Centers the text
                        }}
                      >
                        Qtd. {bookings.length}
                      </Typography>
                    )}
                  </Stack>
                  {bookings.map((booking) => (
                    <BookingCard
                      done={bottomNavigation === 1}
                      key={booking.id}
                      booking={booking}
                    />
                  ))}
                </Box>
              )
            )}
          {rawData && visualization === "table" && (
            <Box sx={{ mb: 4 }}>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  mb: 1,
                }}
              >
                <Typography
                  variant="h6"
                  color={"primary"}
                  sx={{
                    fontWeight: 700, // Make the text bold for emphasis
                    textTransform: "uppercase", // Makes the date stand out
                    letterSpacing: "0.1em", // Adds spacing between letters for clarity
                    lineHeight: 1.5, // Improves readability
                    textAlign: "center", // Centers the text
                  }}
                >
                  Tabela
                </Typography>
              </Stack>
              <BookingTable rawData={rawData} />
            </Box>
          )}
        </Box>
      )}
      {dataLength < 1 && (
        <Typography sx={{ textAlign: "center" }} variant="h6">
          Nenhuma reserva para mostrar.
        </Typography>
      )}
      {data && (
        <PanelNavigation
          value={bottomNavigation}
          dataLength={visualization === "table" ? rawData!.length : dataLength}
          onChange={setBottomNavigation}
        />
      )}
      {phoneNumber && (
        <Modal
          open={phoneNumber}
          onClose={() => setPhoneNumber(null)}
          onClick={() => handleOpenWithNumber(phoneNumber)}
          title={phoneNumber}
          description={
            "Parece que você tem um número de telefone copiado, deseja criar uma reserva?"
          }
          closeButton={"Não"}
          continueButton={"Adicionar reserva"}
        />
      )}
    </Box>
  );
}
