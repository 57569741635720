import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface Props {
  open: boolean;
  onClose: () => void;
  onClick: () => void;
  title: string;
  description: string;
  closeButton: string;
  continueButton: string;
}

export default function Modal({
  open,
  onClose,
  onClick,
  title,
  description,
  closeButton,
  continueButton,
}: Props) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{closeButton}</Button>
        <Button onClick={onClick}>{continueButton}</Button>
      </DialogActions>
    </Dialog>
  );
}
