import React from "react";
import { useNavigate } from "react-router";

import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import VehicleForm from "../forms/VehicleForm";

export default function AddVehiclePage() {
  const navigate = useNavigate();

  const goBack = () => navigate("/admin-management/others/vehicles");

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: "#181818" }}>
        <Toolbar>
          <IconButton
            onClick={goBack}
            size="large"
            edge="start"
            aria-label="menu"
            color="inherit"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Adicionar veículo
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 2 }}>
        <VehicleForm />
      </Box>
    </Box>
  );
}
