import React from "react";
import { BrowserRouter, Route, Routes } from "react-router";

import FloatingActionButtonSize from "./components/FloatingButton";

import App from "./App";

import AdminDashboardPage from "./admin";
import AddClientPage from "./admin/pages/AddClientPage";
import EditClientPage from "./admin/pages/EditClientPage";
import AddDriverPage from "./admin/pages/AddDriverPage";
import AddVehiclePage from "./admin/pages/AddVehiclePage";
import EditVehiclePage from "./admin/pages/EditVehiclePage";
import EditDriverPage from "./admin/pages/EditDriverPage";
import RecognizeClientPage from "./admin/pages/RecognizeClientPage";
import ClientsPage from "./admin/pages/ClientsPage";
import SettingsPage from "./admin/pages/SettingsPage";
import DriversPage from "./admin/pages/DriversPage";
import VehiclesPage from "./admin/pages/VehiclesPage";
import ClientBookingsPage from "./admin/pages/ClientBookingsPage";

import ProtectedRoute from "./utils/ProtectedRoute";

import VoucherPage from "./pages/Voucher";
import CheckinPage from "./pages/CheckinPage";

import AuthenticatePage from "./auth";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="*"
          element={
            <>
              <FloatingActionButtonSize />
              <App />
            </>
          }
        />
        <Route path="authenticate" element={<AuthenticatePage />} />
        {/*  Protected Route Start */}
        <Route path="admin-management" element={<ProtectedRoute />}>
          <Route index element={<AdminDashboardPage />} />
          <Route path="ia-add" element={<RecognizeClientPage />} />
          <Route path="add" element={<AddClientPage />} />
          <Route path="others">
            <Route path="clients">
              <Route index element={<ClientsPage />} />
              <Route path=":phone" element={<ClientBookingsPage />} />
            </Route>
            <Route path="settings" element={<SettingsPage />} />
            <Route path="drivers">
              <Route index element={<DriversPage />} />
              <Route path="add" element={<AddDriverPage />} />
              <Route path="edit">
                <Route path=":id" element={<EditDriverPage />} />
              </Route>
            </Route>
            <Route path="vehicles">
              <Route index element={<VehiclesPage />} />
              <Route path="add" element={<AddVehiclePage />} />
              <Route path="edit">
                <Route path=":id" element={<EditVehiclePage />} />
              </Route>
            </Route>
          </Route>
          <Route path="edit">
            <Route index path=":id" element={<EditClientPage />} />
          </Route>
        </Route>
        {/*  Protected Route End */}
        <Route path="voucher">
          <Route index path=":id" element={<VoucherPage />} />
        </Route>
        <Route path="checkin">
          <Route index path=":id" element={<CheckinPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
