import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Alert,
  AlertTitle,
  AppBar,
  Box,
  Button,
  Divider,
  IconButton,
  LinearProgress,
  LinearProgressProps,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  OutlinedInput,
  InputLabel,
  FormControl,
} from "@mui/material";
import { styled } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AutoAwesome, CameraEnhance, Image } from "@mui/icons-material";

import useImageToText from "../../hooks/useImageToText";
import { useNotification } from "../../contexts/NotificationContext";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ color: "text.secondary" }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function RecognizeClientPage() {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [message, setMessage] = useState<any>(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [processing, setProcessing] = useState(false);

  const { ocrResult, loading, progress, error, extractTextFromImage } =
    useImageToText();

  const { showNotification } = useNotification();

  const navigate = useNavigate();

  const goBack = () => navigate("/admin-management");

  useEffect(() => {
    if (selectedImage) {
      extractTextFromImage(selectedImage);
    }
  }, [extractTextFromImage, selectedImage]);

  useEffect(() => {
    if (ocrResult) {
      setMessage(ocrResult);
    }
  }, [ocrResult]);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedImage(event.target.files[0]);
    }
  };

  const handleTabChange = (_: React.SyntheticEvent, newIndex: number) => {
    setMessage(null);
    setSelectedImage(null);
    setTabIndex(newIndex);
  };

  const handleProcessData = async () => {
    setProcessing(true);

    showNotification("info", "Processando informações...");

    try {
      const response = await fetch(
        "https://extractmessagefields-oiocslysma-uc.a.run.app",
        {
          method: "POST",
          body: JSON.stringify({
            message,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        return showNotification(
          "error",
          "Ocorreu um erro ao processar as informações."
        );
      }

      const receivedData = await response.json();

      let parsedData = {};

      if (receivedData.error) {
        const cleanedMessage = receivedData.message
          .replace(/```json/g, "")
          .replace(/```/g, "");

        parsedData = JSON.parse(cleanedMessage);
      } else {
        parsedData = receivedData;
      }

      showNotification("success", "Informações processadas com sucesso!");
      navigate("/admin-management/add", {
        state: {
          data: parsedData,
        },
      });
    } catch (err) {
      return showNotification(
        "error",
        "Ocorreu um erro ao processar as informações."
      );
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: "#181818" }}>
        <Toolbar>
          <IconButton
            onClick={goBack}
            size="large"
            edge="start"
            aria-label="menu"
            color="inherit"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Adicionar com IA
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          p: 3,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Box>
          <Typography variant="h5" sx={{ display: "inline" }}>
            Adicione uma reserva usando o auxílio de{" "}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              display: "inline",
              background: "linear-gradient(270deg, #6a0dad, #1e90ff)",
              backgroundSize: "400% 400%",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              animation: "gradientAnimation 3s ease infinite",
              fontWeight: 700,
            }}
          >
            Inteligência Artificial
          </Typography>
        </Box>
        <Divider />
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab sx={{ width: "50%" }} label="Usar Imagem" />
          <Tab sx={{ width: "50%" }} label="Usar Texto" />
        </Tabs>
        {tabIndex === 0 && (
          <Box
            sx={{
              textAlign: "center",
              display: "flex",
              gap: 3,
              flexDirection: "column",
            }}
          >
            {selectedImage ? (
              <Box sx={{ mt: 2 }}>
                <img
                  src={URL.createObjectURL(selectedImage)}
                  alt="Selected"
                  style={{ maxWidth: "100%", maxHeight: "300px" }}
                />
              </Box>
            ) : (
              <Box sx={{ border: "3px dashed gray", borderRadius: 4, p: 3 }}>
                <Image sx={{ fontSize: 86, color: "gray" }} />
                <Typography variant="h6" color="gray">
                  Sua imagem aparecerá aqui.
                </Typography>
              </Box>
            )}
            {!loading && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Button
                  component="label"
                  role={undefined}
                  tabIndex={-1}
                  variant="outlined"
                  color="primary"
                  startIcon={<CameraEnhance />}
                >
                  {selectedImage ? "Alterar Imagem" : "Adicionar Imagem"}
                  <VisuallyHiddenInput
                    type="file"
                    onChange={handleImageChange}
                    multiple
                  />
                </Button>
              </Box>
            )}
            {loading && (
              <>
                <Typography variant="h5" sx={{ mt: 2 }}>
                  Carregando...
                </Typography>
                <LinearProgressWithLabel value={progress} />
              </>
            )}
            {error && (
              <Typography variant="body1" color="error" sx={{ mt: 2 }}>
                Erro: {error}
              </Typography>
            )}
          </Box>
        )}
        {tabIndex === 1 && (
          <FormControl sx={{ textAlign: "none" }}>
            <InputLabel style={{ color: "#181818" }}>Texto</InputLabel>
            <OutlinedInput
              fullWidth
              onChange={(e) => setMessage(e.target.value)}
              label="Texto"
              placeholder="Texto com informações sobre a reserva."
              multiline
              rows={3}
            />
          </FormControl>
        )}

        {!loading && (
          <>
            <Divider />
            <Button
              variant="contained"
              onClick={handleProcessData}
              startIcon={<AutoAwesome />}
              disabled={loading || !message || processing}
              sx={{
                background: "linear-gradient(270deg, #6a0dad, #1e90ff)",
                backgroundSize: "400% 400%",
                animation: "gradientAnimation 3s ease infinite",
                color: "#fff",
                fontWeight: 700,
                textTransform: "uppercase",
                padding: "8px 16px",
                borderRadius: "8px",
                "&:hover": {
                  background: "linear-gradient(270deg, #1e90ff, #6a0dad)",
                },
                "&:disabled": {
                  background: "#ccc",
                  color: "#666",
                },
              }}
            >
              Processar
            </Button>
          </>
        )}
      </Box>
      <Box sx={{ p: 3, pb: 0 }}>
        <Alert severity="info">
          <AlertTitle>
            <strong>Aviso</strong>
          </AlertTitle>
          Este recurso utiliza <strong>ChatGPT</strong>, portando, há cobranças,
          e <strong>SEMPRE</strong> verifique se as informações estão corretas
          antes de concluir a reserva.
        </Alert>
      </Box>
    </Box>
  );
}
