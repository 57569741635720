import React from "react";
import { useNavigate } from "react-router";
import { Box, Divider, Paper, Typography, useTheme } from "@mui/material";
import {
  ArrowForward,
  DirectionsCar,
  ExitToApp,
  Groups,
  PersonOff,
  Settings,
} from "@mui/icons-material";
import { styled } from "@mui/styles";

import { auth } from "../../firebase/firebase";
import { useNotification } from "../../contexts/NotificationContext";

const Item = styled(Paper)(() => ({
  padding: 12,
}));

export default function OthersScreen() {
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const theme = useTheme();

  const handleSignOut = () => {
    auth.signOut().then(() => {
      return showNotification("info", "Você se desconectou de sua conta.");
    });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Item
        onClick={() => navigate("others/clients")}
        sx={{
          backgroundColor: theme.palette.primary.main,
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
            <Groups sx={{ color: "white" }} />
            <Typography color="white" variant="h6">
              Clientes
            </Typography>
          </Box>
          <ArrowForward sx={{ color: "white" }} />
        </Box>
      </Item>
      <Divider />
      <Item
        onClick={() => navigate("others/drivers")}
        sx={{
          backgroundColor: theme.palette.secondary.main,
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
            <PersonOff sx={{ color: "white" }} />
            <Typography color="white" variant="h6">
              Motoristas
            </Typography>
          </Box>
          <ArrowForward sx={{ color: "white" }} />
        </Box>
      </Item>
      <Item
        onClick={() => navigate("others/vehicles")}
        sx={{
          backgroundColor: theme.palette.secondary.main,
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
            <DirectionsCar sx={{ color: "white" }} />
            <Typography color="white" variant="h6">
              Veículos
            </Typography>
          </Box>
          <ArrowForward sx={{ color: "white" }} />
        </Box>
      </Item>
      <Divider />
      <Item
        onClick={() => navigate("others/settings")}
        sx={{
          backgroundColor: "#181818",
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
            <Settings sx={{ color: "white" }} />
            <Typography color="white" variant="h6">
              Ajustes
            </Typography>
          </Box>
          <ArrowForward sx={{ color: "white" }} />
        </Box>
      </Item>
      <Item
        onClick={handleSignOut}
        sx={{ backgroundColor: theme.palette.error.main, borderRadius: "20px" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
            <ExitToApp sx={{ color: "white" }} />
            <Typography color="white" variant="h6">
              Sair
            </Typography>
          </Box>
          <ArrowForward sx={{ color: "white" }} />
        </Box>
      </Item>
    </Box>
  );
}
