import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { get, ref } from "firebase/database";

import DriverForm from "../forms/DriverForm";

import { database } from "../../firebase/firebase";

export default function EditDriverPage() {
  const [data, setData] = useState<any>();
  const [key, setKey] = useState<any>();

  const { id } = useParams();
  const navigate = useNavigate();

  const goBack = () => navigate("/admin-management/others/drivers");

  useEffect(() => {
    if (id) {
      get(ref(database, `drivers/${id}`)).then((snapshot: any) => {
        if (snapshot.exists()) {
          setData(snapshot.val());
          setKey(snapshot.key);
        } else {
          return navigate("/admin-management");
        }
      });
    }
  }, [id, navigate]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: "#181818" }}>
        <Toolbar>
          <IconButton
            onClick={goBack}
            size="large"
            edge="start"
            aria-label="menu"
            color="inherit"
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Editar motorista
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 2 }}>
        <DriverForm id={key} driver={data} />
      </Box>
    </Box>
  );
}
