import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router";
import dayjs from "dayjs";

import {
  Box,
  Button,
  ButtonProps,
  Divider,
  IconButton,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import {
  EmailOutlined,
  Instagram,
  PhoneOutlined,
  TaskAlt,
  LocationOn,
  Google,
  Apple,
} from "@mui/icons-material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import { get, ref } from "firebase/database";

import { database } from "../firebase/firebase";

import black_logo from "../assets/images/black_logo.png";

import LoadingScreen from "../utils/LoadingScreen";

const WhatsappButton = styled(Button)<ButtonProps>(() => ({
  backgroundColor: "#25d366 !important",
}));

const InfoRow = ({ label, value }: any) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      padding: "4px 0",
    }}
  >
    <Typography variant="h6" component="span">
      {label}
    </Typography>
    <Box
      sx={{
        flex: 1,
        height: "1px",
        background:
          "repeating-linear-gradient(to right, #ddd, #ddd 2px, transparent 2px, transparent 4px)",
        margin: "0 8px",
      }}
    />
    <Typography variant="h6" component="span" sx={{ fontWeight: "bold" }}>
      {value}
    </Typography>
  </Box>
);

export default function Voucher() {
  const [booking, setBooking] = useState<any>();
  const [passengersName, setPassengersName] = useState("");
  const [currency, setCurrency] = useState("R$");

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    get(ref(database, `booking/${id}`)).then((snapshot) => {
      if (snapshot.exists()) {
        setBooking(snapshot.val());
      } else {
        return navigate("/");
      }
    });
  }, [id, navigate]);

  useEffect(() => {
    if (booking && booking.currency) {
      switch (booking.currency) {
        case "dollar":
          setCurrency("$");
          break;
        case "euro":
          setCurrency("€");
          break;
        case "peso":
          setCurrency("₱");
          break;
        case "real":
        default:
          setCurrency("R$");
          break;
      }
    }
  }, [booking]);

  useEffect(() => {
    if (booking && booking.passengersQty >= 2) {
      const passengers = [];

      passengers.push(booking.name);

      for (let i = 2; i <= booking.passengersQty; i++) {
        passengers.push(`${booking[`passenger${i}`]}`);
      }

      setPassengersName(passengers.join(", "));
    }
  }, [booking]);

  if (!booking) {
    return <LoadingScreen />;
  }

  const openGoogleMaps = () => {
    const url = `https://www.google.com/maps/search/?q=${encodeURIComponent(
      booking.destination
    )}`;

    window.open(url, "_blank");
  };

  const isAppleDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();

    return /iphone|ipod|ipad|macintosh/.test(userAgent);
  };

  const createGoogleCalendarEvent = () => {
    const title = `Transfer ${booking.origin} para ${booking.destination}`;
    const details = `Viagem de ${booking.origin} para ${booking.destination}, ${booking.passengersQty} passageiros.`;
    const location = booking.destination;
    const startDate = new Date(booking.date);
    const endDate = new Date(booking.date);

    endDate.setHours(endDate.getHours() + 1);

    const url = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
      title
    )}&details=${encodeURIComponent(details)}&location=${encodeURIComponent(
      location
    )}&dates=${startDate.toISOString().replace(/-|:|\.\d+/g, "")}/${endDate
      .toISOString()
      .replace(/-|:|\.\d+/g, "")}`;

    window.open(url, "_blank");
  };

  const formatDate = (date: any) => {
    const adjustedDate = new Date(date.getTime() - 3 * 60 * 60 * 1000);

    const yyyy = adjustedDate.getUTCFullYear();
    const mm = String(adjustedDate.getUTCMonth() + 1).padStart(2, "0");
    const dd = String(adjustedDate.getUTCDate()).padStart(2, "0");
    const hh = String(adjustedDate.getUTCHours()).padStart(2, "0");
    const min = String(adjustedDate.getUTCMinutes()).padStart(2, "0");
    const sec = String(adjustedDate.getUTCSeconds()).padStart(2, "0");

    return `${yyyy}${mm}${dd}T${hh}${min}${sec}Z`;
  };

  const createAppleCalendarEvent = () => {
    const title = `Transfer ${booking.origin} para ${booking.destination}`;
    const details = `Viagem de ${booking.origin} para ${booking.destination}, ${booking.passengersQty} passageiros.`;
    const location = booking.destination;
    const startDate = new Date(booking.date);
    const endDate = new Date(booking.date);

    endDate.setHours(endDate.getHours() + 1);

    const startDateFormatted = formatDate(startDate);
    const endDateFormatted = formatDate(endDate);

    const icsContent = `BEGIN:VCALENDAR
BEGIN:VEVENT
SUMMARY:${title}
DESCRIPTION:${details}
LOCATION:${location}
DTSTART;TZID=America/Sao_Paulo:${startDateFormatted}
DTEND;TZID=America/Sao_Paulo:${endDateFormatted}
STATUS:CONFIRMED
SEQUENCE:1
URL:https://www.transferonfloripa.com.br/voucher/${id}
BEGIN:VALARM
TRIGGER:-PT1H
DESCRIPTION:Lembrete
ACTION:DISPLAY
END:VALARM
END:VEVENT
END:VCALENDAR`;

    const blob = new Blob([icsContent], { type: "text/calendar" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");

    link.href = url;
    link.download = `transfer.ics`;

    link.click();
  };

  const handleOpenCheckin = () => {
    const url = `https://transferonfloripa.com.br/checkin/${id}`;

    window.open(url);
  };

  return (
    booking && (
      <Box sx={{ p: 3, backgroundColor: "#262626", minHeight: "100%" }}>
        <Stack spacing={3}>
          <Paper
            elevation={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              p: 3,
              textAlign: "center",
              borderRadius: 2,
            }}
          >
            <Stack spacing={2}>
              <span style={{ display: "flex", justifyContent: "center" }}>
                <Link to="/">
                  <img style={{ width: "224px" }} src={black_logo} alt="logo" />
                </Link>
              </span>
              <Typography variant="h5">{booking.name}</Typography>
              <Typography variant="h6">
                Obrigado por nos escolher para sua viagem! Sua reserva para{" "}
                <span style={{ fontWeight: "bold" }}>
                  {dayjs(booking.date).format("DD/MM/YYYY, HH:mm")}
                </span>{" "}
                foi confirmada!
              </Typography>
              <Box>
                <InfoRow
                  label="Valor:"
                  value={`${currency} ${Number(booking.value)}`}
                />
                {booking.paidValue > 0 && (
                  <InfoRow
                    label="Valor pago:"
                    value={`${currency} ${Number(booking.paidValue)}`}
                  />
                )}
                {passengersName && (
                  <InfoRow label="Passageiros:" value={passengersName} />
                )}
                <InfoRow label="Telefone:" value={booking.phone} />
                <InfoRow label="Origem:" value={booking.origin} />
                <InfoRow label="Destino:" value={booking.destination} />
                {booking.flight && (
                  <InfoRow label="Voo:" value={booking.flight} />
                )}
                <InfoRow
                  label="Data:"
                  value={dayjs(booking.date).format("DD/MM/YYYY")}
                />
                <InfoRow
                  label="Horário:"
                  value={dayjs(booking.date).format("HH:mm")}
                />
              </Box>
              <Typography variant="h6">
                Caso tenha alguma dúvida, entre em contato pelo WhatsApp, e não
                se esqueça de conferir as instruções de embarque!
              </Typography>
              <Button
                onClick={handleOpenCheckin}
                startIcon={<TaskAlt />}
                variant="contained"
                color="success"
              >
                Instruções de embarque
              </Button>
              <WhatsappButton
                startIcon={<WhatsAppIcon />}
                variant="contained"
                href="https://wa.me/5548996523066"
              >
                WhatsApp
              </WhatsappButton>
              <Divider>
                <Typography color="GrayText">Outros</Typography>
              </Divider>
              {isAppleDevice() && (
                <Button
                  startIcon={<Apple />}
                  variant="contained"
                  onClick={createAppleCalendarEvent}
                  sx={{
                    backgroundColor: "#1c1c1e",
                    "&:hover": {
                      backgroundColor: "#1c1c1e",
                    },
                  }}
                >
                  Adicionar ao Apple Calendar
                </Button>
              )}
              <Button
                startIcon={<LocationOn />}
                variant="contained"
                onClick={openGoogleMaps}
                sx={{
                  backgroundColor: "#1a73e8",
                  "&:hover": {
                    backgroundColor: "#1a73e8",
                  },
                }}
              >
                Abrir no Google Maps
              </Button>
              <Button
                startIcon={<Google />}
                variant="contained"
                onClick={createGoogleCalendarEvent}
                sx={{
                  backgroundColor: "#1a73e8",
                  "&:hover": {
                    backgroundColor: "#1a73e8",
                  },
                }}
              >
                Adicionar ao Google Calendar
              </Button>
            </Stack>
          </Paper>
          <Stack>
            <Link to="/" style={{ textAlign: "center", color: "gray" }}>
              <Typography>Transfer on Floripa</Typography>
            </Link>
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <IconButton
                href="https://wa.me/5548996523066"
                sx={{ color: "gray" }}
              >
                <WhatsAppIcon />
              </IconButton>
              <IconButton
                href="https://www.instagram.com/transfer.onfloripa/"
                sx={{ color: "gray" }}
              >
                <Instagram />
              </IconButton>
              <IconButton
                href="mailto:transfer.onfloripa@gmail.com"
                sx={{ color: "gray" }}
              >
                <EmailOutlined />
              </IconButton>
              <IconButton href="tel://5548996523066" sx={{ color: "gray" }}>
                <PhoneOutlined />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    )
  );
}
